
.MuiOutlinedInput-root {
    width:100%;
    max-height: 41px;
}

.check{
    position: relative;
    left: 11px;
}

div.reservationPage .headerNav { 
    display: none;
}


.form-bg{
    background-color:white;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    border: 2px solid lightgrey;
}
.form-body{
   
}
.pd_16{
    padding-bottom: 25px !important;
}
.title-bar{
    background-color: #01bddddb;
    color: #fff;
    padding: 10px;
    border-radius: 7px;
    margin-bottom: 1rem;
    font-size: 1.1em;
    font-weight:600;
}

.title-bar > p {
    margin: 0.4em 0 0.6em;
}

.date-slash{
    font-size: 21px;
    font-weight: 100;
    margin-top: -7px;
    margin-left: 5px;
    margin-right: 5px;
    color: #666666;
}
.dateTag{
    border: 1px solid #757575;
    border-radius: 5px;
    display: flex;
    /* margin-right: 14px; */
    padding: 10px 10px 0px 10px;
}
.date_attr{
    position: relative;
    top: 1px;
}

.baggage-div{
    width: 96%;
    /* height: 40px; */
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding: 6px;

}
.greenText{
    color: #1e5881;
}
.pinkText{
    color: #cf5770;
}

.summaryDiv{
    line-height: 11px;
}
.finalPriceDiv{
    border: 2px solid #cf5770;
    text-align: center;
    margin: auto;
    height: 100px;
    width: 130px;
    border-radius: 12px; 
    padding: 20px;
    line-height: 5px;
    color: #cf5770;
    margin-top: 10px;
    font-size: 30px;
}

.submitButton{
    width: 100%;
    height: 60px;
    background-color: #cf5770;
    border-radius: 10px;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 26px;
}

/*.form-label, .portionHeading{
    font-weight: 600;
}*/

.price_span{
    float: left;
    padding-left: 10px;
}

.extrasDiv{
    float: left;
    text-align: center;
    line-height: 0px;
    margin-left: 10px;
}

.policy-div .ant-form-item-explain.ant-form-item-explain-error {
    position: absolute;
    margin: 0 1.2rem;
}

@media only screen and (max-width: 500px) {
    /*.form-bg {
        padding-bottom:0;
    }*/
 
    div.reservationPage {
        margin-top: 60px;
    }

    div.reservationPage .headerNav {
        display: block;
    }

    .baggage-div {
      width: 95%;
    }
    .finalPriceDiv{
        height: 80px;
        width: 90px;
        padding: 10px;
       
    }
    .left_green{
        width: 100%;
        text-align: left;
        margin-left: 11px;
    }
    .summaryDiv{
        line-height: 18px;
    }
  .finalPriceDiv {
      font-size:24px ;
  }
    
  }


  .MuiSelect-selectMenu {
    text-overflow: unset !important;
  }







  .antd_input{
    width: 100%;
    height: 41px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    font-size: 17px;
}

.policy-div .ant-form-item {
    margin-top: 0;
}

.ant-form-item {
    margin-top: .5em;
}

.form-body .form-label {
    font-size: 15px;
    margin-bottom: 0;
    width: 100%;
}

.ant-form-item{
margin-bottom: 0px !important;
}
.ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f;
}
.ant-form-item-explain, .ant-form-item-extra {
    clear: both;
    color: rgba(0,0,0,.45);
    font-size: 14px;
    line-height: 1.5715;
    min-height: 16px;
    transition: color .3s cubic-bezier(.215,.61,.355,1);
}
.ant-input-number {
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0,0,0,.85);
    display: inline-block;
    font-feature-settings: "tnum";
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    min-width: 0;
    padding: 0;
    position: relative;
    transition: all .3s;
    width: 100%;
    width: 90px;
}


.ant-input-number-handler-wrap {
    background: #fff;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 2px 2px 0;
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .24s linear .1s;
    width: 22px;
}
.ant-form-item .ant-input-number-handler-wrap {
    z-index: 2;
}

.input_number{
    outline: none;
    width: 100%;
    border: none;
    margin-bottom: 2px;
}

.ant-checkbox {
    font-feature-settings: "tnum";
    box-sizing: border-box;
    color: rgba(0,0,0,.85);
    cursor: pointer;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: 1;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
    top: .2em;
    white-space: nowrap;
}
.ant-checkbox+span {
    padding-left: 8px;
    padding-right: 8px;
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 18px;
}
.ant-input-suffix {
    align-items: center;
    display: flex;
    flex: none;
}
.ant-input-affix-wrapper {
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    color: rgba(0,0,0,.85);
    display: inline-block;
    display: inline-flex;
    font-size: 14px;
    line-height: 1.5715;
    min-width: 0;
    padding: 4px 11px;
    position: relative;
    transition: all .3s;
    width: 100%;
}
.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
    -webkit-animation-name: diffZoomIn1!important;
    animation-name: diffZoomIn1!important;
    color: #52c41a;
}

.ant-form-item-explain.ant-form-item-explain-error {
    font-weight: bold;
}

.anticon {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    display: inline-block;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
}
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    -webkit-animation: zoomIn .3s cubic-bezier(.12,.4,.29,1.46);
    animation: zoomIn .3s cubic-bezier(.12,.4,.29,1.46);
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-top: -10px;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    visibility: visible;
    width: 32px;
    z-index: 1;
}
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
    -webkit-animation-name: diffZoomIn2!important;
    animation-name: diffZoomIn2!important;
    color: #ff4d4f;
}
.anticon {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    display: inline-block;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
}
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    animation: zoomIn .3s cubic-bezier(.12,.4,.29,1.46);
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-top: -10px;
    pointer-events: none;
    position: relative;
    right: 0;
    text-align: center;
    top: -23px;
    visibility: visible;
    width: 32px;
    float: left;
    z-index: 1;
}


.mr-6 {
    margin-right: 0.3rem!important;
}

h4 {
    font-weight: bold;
}

.box {
    border: 1px solid #999;
    border-radius: 5%;
    padding: 0.2em 0.5em;
}

.box .top { 
    font-weight:bold;
    color: #666;
}

.box .caption {
    text-align: center;
    /*font-weight: bold;*/
}

.box .picture {
    
    height:100px;
    width:75px;
    background-size:contain;
    background-repeat:no-repeat;
    text-align:center;
    background-position:center;
}


.box .bottom {
    font-size: 1.2em;
    font-weight: bold;
    color: #1e5881;
    vertical-align: middle;
}

.box .cb {
    color: #1e5881 !important; 
    padding: 0;
}

.box label {
    width: 100%;
}

/* C = Checked bag */
.box .center .picture.C {
    background-image:url(/images/baggage/suitcase.svg);
}

/* S= Small */
.box .center .picture.S {
    background-image:url(/images/baggage/backpack.svg);
}

/* T= Trolly */
.box .center .picture.T {
    background-image:url(/images/baggage/trolly.svg);
}

div#finishProgress {
    text-align: center;
}

div#finishProgress.hidden {
    display: none;
}

button#finishBtn.hidden {
    display: none;
}

#errorDialog {
    font-size: 1.3em;
    font-weight:bold;
}

.ant-form-item-explain-error {
    color: red;
    font-weight: bold;
}

@media only screen and (max-width: 420px) {
    .mr-6 {
        margin-right: 2rem!important;
    }

}