/* Fixing infinite-calendar Hebrew support */
.Cal__Header__range .Cal__Header__wrapper:first-child:before {
  display: none;
}

.Cal__Header__range .Cal__Header__wrapper:last-child {
  margin-left: 0 !important;
  text-align: left !important;
}

.Cal__Header__root {
  text-align: right;
  border-radius: 20px 20px 0 0 !important;
}

.Cal__Header__range .Cal__Header__wrapper .Cal__Header__date {
  direction: rtl;
}

.Cal__Header__wrapper {
  text-align: right !important;
  flex-direction: column-reverse !important;
}

.Cal__Header__range {
  flex-direction: row-reverse !important;
  direction: ltr !important;
}

.Cal__Container__root {
  position: relative !important;
  width: 40%;
  margin: 0 auto;
  /*min-width: 350px;   */

}
.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.Cal__Month__row:first-child {
  text-align: left !important;
}
ul.Cal__Month__row.Cal__Month__partial {
  text-align: right;
}
span.Cal__Day__month {
  font-size: 1em;
  font-weight: 600;
}


.Cal__Day__range.Cal__Day__selected.Cal__Day__start:before {
  right: 0;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__start:before, .Cal__Day__range.Cal__Day__selected.Cal__Day__end:before {
  content: '';
  position: absolute;
  top: 50%;
  width: 50%;
  height: 52px;
  margin-top: -26px;
  box-shadow: inset 56px 56px;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after {
  right: inherit !important;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after, .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  content: initial !important;
  position: initial !important;
  top: initial !important;
  width: initial !important;
  height: initial !important;
  margin-top: initial !important;
  box-shadow: initial !important;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}


#combo-box-demo::-webkit-input-placeholder {
  color: #000;
  filter: opacity(1);
  font-size: 1em;
  opacity: 1;
  padding: 0.2em 0 0.4em;
  font-family: assistant;
  font-weight: 700;
}

.MuiAutocomplete-endAdornment {
  direction: ltr;
}

.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}
.customModal {
  background: #b2dbbf;
  max-width: 500px;
  width: 100%;
}

body {
  direction:rtl;
  /*background-color: #eee;*/
}

.top-header {
  background-image: url(/images/intro/depositphotos_50930169-stock-photo-young-couple-of-tourist-in-large.jpg);
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-attachment: fixed;
  height: 560px;
}

.top-header::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  /*background-image: linear-gradient(rgb(0 0 0 /80%), rgb(0 0 0 /0%), rgb(0 0 0 /0%));*/
  background-image: linear-gradient(rgb(0 0 0 /75%), rgb(0 0 0 /42%), rgb(0 0 0 /-20%));
    max-height: 360px;
}

.show-item {display:block;}
.hide-item {display:none;}

#sidemenu_toggle {display:none}

.react-responsive-modal-modal {
  text-align: center !important;
  z-index:100004 !important;
}

.react-responsive-modal-modal > div {
  padding:1.5rem;
}

.react-responsive-modal-modal > div > h2 {
  font-weight:bold;
  margin-bottom: 1rem;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

#chat_icon {position: fixed;
  bottom: 5px;
  right: 5px;
  width: 80px;
}

#logo {
    position: relative;
    top: 0;
    z-index:10001;
    margin-right: .25em;
}

.logo a {color: #fff;}


#headerNav .nav-sections {
  place-items: center;
  display: flex;
}

#headerNav .nav-sections .left-nav {
  display:none;
}

.headerNav .logo .logo-svg {
    height:75px;
    color:#fff;
    margin-top: 5px;
}

#headerNav .menu-button {
  color: #fff;
}

.headerNav .right-nav {
  font-size: 2rem;
  margin: 0 0 0 1rem;
  display: flex !important;
  align-items: center;
  color: #fff;

}

.menu-content {
  width: 100%;
  height: 1024px;
  background: #1085a7;
  color: #fff;
  text-align: right;
  position:absolute;
  top: 80px;
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  z-index: 1000000;
}


@keyframes slideIn {
  0% { transform: translateY(100%); }
}

#headerNav .menu-button {
  margin: 0 0.4rem 0 0;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
}

.menu-content ul {
  padding: 0;
  margin: 0;
  background: #1085a7;
}

.menu-content li {
  font-size: 2rem;
  list-style: none;
  font-weight: 600;
  padding: 1rem 3rem;
  border-bottom: 1px solid #fff;
}

.menu-content li.mobile-only {display:none;}

.menu-content li.subitem {
padding-right: 2.5rem;
}

.menu-content li.first-item {
  font-size: 2.5rem;
  font-weight: 700;
  background: #1b3344;
}

.menu-content li a {
color: #fff;
text-decoration: none;  ;
}


.menu-content li.first-item i.fa {
font-weight: 200;
margin: 0 .8rem 0 0 ;
border: 1px solid #fff;
border-radius: 50%;
padding: 0.5rem;
font-size: 1rem;
width: 16px;
text-align: center;
float: left;
}

.menu-content li i.fa {
margin: 0 0 0 .5rem;
}


.preloader {
  max-width: 45%;
  margin: 3rem auto 0;
  padding: 1rem;
  color: #1f5981;
}

.preloader h2 {
  margin: 0 auto 1rem ;
}

.noresults {
  margin: 3em auto 10em;
}

h2.light {
  font-weight: 400;
  font-size: 2.5rem;
  margin-top: 4rem;
  color: #555;
  margin-bottom: 0;
}

.App, body {
  font-family:  'Assistant', sans-serif;
  text-align: center;
}

.App.Terms Main, .App.MemberTerms Main, .App.About Main , .App.Media Main , .App.About Main{
  text-align: right;
  margin: 100px auto 2em auto;
  padding: 1rem 2rem 1rem ;
  max-width: 70%;
  font-size: 1rem;
}

.App.About Main h1 {
  font-size: 3rem;
}

/*.App.About Main {
  text-align: right;
  margin: 0 auto;
  padding: 0;
  max-width: 85%;
}*/

.App.Media Main {
  margin: 0 auto;
  padding: 1rem 2rem;
  max-width: 53%;
}

.App.Terms .headerImg {display:none;}

#logo > img {
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 75px;
}

h1 {
  font-size: 3em;
}

button, select {cursor: pointer;}


h1.mainHeader {
  font-size: 3.8rem;
  font-weight: 800;
  color: #333;
  margin-top: 1rem;
}

nav > ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
}

nav li {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

nav li a {
  -webkit-border-radius: 20px;
    border-radius: 20px;
    overflow: hidden;
    padding: 8px 1.25rem!important;
    font-weight: 600;

}


nav a {
  color:#fff;
  font-size: 1.6rem;
  text-decoration: none;
  font-weight: 500;
}

/*nav a:hover {
  color: yellow;
}*/


header {
  /*font-size: calc(5px + 1vmin);*/
  color: #fff;
  direction:rtl;
  background: #fff;
   /*background: url(/images/header-background-beach.jpg) 0% 100% / contain no-repeat padding-box padding-box rgb(24, 192, 195);*/
  /*background: linear-gradient(    235deg,    rgb(16 171 207) 30%,    rgba(1 189 221)  ),url(/images/header-background-beach.jpg) no-repeat left top;*/
  /*background: linear-gradient(    235deg,   rgb(31 89 129) 30%,    rgba(1 189 221)  ),url(/images/header-background-beach.jpg) no-repeat left top;*/
  /*margin: .5em;*/
}

.headerNav {
    /*background: -webkit-linear-gradient(90deg,#83dcea 31%,#00abc9 69%);
    background: -webkit-gradient(linear,left top,right top,color-stop(31%,#83dcea),color-stop(69%,#00abc9));
    background: -webkit-linear-gradient(left,#83dcea 31%,#00abc9 69%);
    background: -o-linear-gradient(left,#83dcea 31%,#00abc9 69%);
    background: linear-gradient(90deg,#124b71  31%,#00abc9  69%);*/
    /*background: #fff;*/
    top: 0;
    left: 0;
    right: 0;
    /*position: fixed;*/
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    z-index: 1;
    -webkit-animation-name: animationFade;
    -o-animation-name: animationFade;
    animation-name: animationFade;
    -webkit-animation-duration: 5s;
    -o-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    transition-property: all;
	  transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    opacity: 0.98;
}

.headerNav .right-nav {display:none;}
.mobile_navigation {
  display:none;
}

.desktop_navigation ul {
    margin-top: 0;
    margin-bottom: 0;
}

.desktop_navigation li.members a {
  color: #fed102; /* #f2e54e;*/
  background-color: #134a71;
}
.desktop_navigation li a:hover {
    color: #fed102;
}
/*
#headerNav .menu-button:hover {
    color: #fed102;
} 
*/

.search > i.fa.fa-search:hover {
    color: #fed102;
}

/*
div#logo:hover {
    filter: invert(42%) sepia(85%) hue-rotate(-344deg) saturate(8);
    -webkit-filter: invert(42%) sepia(85%) hue-rotate(-344deg) saturate(8);
}
*/

.headerNav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: strech;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
    z-index:1;
  width:100%;
}

header.no-image {
  /*margin-top: 100px;*/
}

.headerNav .wrapper {
  display: flex;
  z-index: 100000;
}


/*.headerNav .overlay {
    background: #135375;
    width: 100%;
    position: absolute;
    min-height: 85px;
    opacity: 0.4;
  }
*/

.headerImg {
  display: flex;
  /*background:url(https://st2.depositphotos.com/5147887/9399/i/950/depositphotos_93997928-stock-photo-beautiful-traveling-couple-on-the.jpg);
  background:url(https://static3.depositphotos.com/1000859/264/i/950/depositphotos_2642179-stock-photo-bright-girl.jpg);
  background:url(https://st3.depositphotos.com/4196725/13785/i/1600/depositphotos_137852844-stock-photo-young-crazy-man-smiling.jpg);*/
  min-height: 550px;
  background-size: cover !important;
  /*background-position-y: center !important;*/
  /*opacity: 0.6;*/
 
}

/*.headerImg.main {background:url(https://st.depositphotos.com/1022806/1470/i/950/depositphotos_14708785-stock-photo-jet-in-flight.jpg);}*/
.headerImg.main {
    background:url(/images/header/bg1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
    background-attachment: fixed;
    background-color: #4b95cf;

  }
.headerImg.up-to-99 {background:url(https://st2.depositphotos.com/5147887/9399/i/950/depositphotos_93997928-stock-photo-beautiful-traveling-couple-on-the.jpg);}
.headerImg.cheap-flights {background:url(https://static3.depositphotos.com/1000859/264/i/950/depositphotos_2642179-stock-photo-bright-girl.jpg);}

.headerImg .slogan {
  max-width: 35%;
  text-align: right;
  margin: 0 15% 2.5rem;
  color: #fff;
  font-size: 4.5rem;
  line-height: 4.5rem;
  font-weight: 800;
  place-self: center;
  -webkit-text-stroke: 1px #1342ff;
  color: white;
  text-shadow: 3px 3px 0 #0089c0, -1px -1px 0 #0089c0, 1px -1px 0 #0089c0, -1px 1px 0 #0089c0, 1px 1px 0 #0089c0;
}


.headerImg .slogan h1 {
  font-size: 6rem;
  margin: 2rem 0;
  line-height: 2rem;
  font-weight: 800;
}


.book_deal_content {
  text-align: right;
  padding: 0 1rem 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  width: 80%;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;
  
}

.book_deal_content .cols_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.book_deal .price .amount {
  font-size: 3rem; 
  font-weight: 600;
  line-height: 1.1;
  border-bottom: 1px solid #aaa;
  text-align: center;
}

.book_deal .price .amount .from {
  font-size: 0.8rem;
}

.order_page .deal_total h2 .from {
  font-size: 0.75rem;
  color: #777;
}

.book_deal .price .caption { 
  text-align: center; 
  font-size: 1rem;
  font-weight: 600;
}

.book_deal_content .cols_wrapper.flight_title  h3 {
  margin: 0;
  display: inline;
  font-size: 1.6rem;
}

.book_deal_content .cols_wrapper.flight_title  { 
  margin:1rem 0 0.5rem;
}

.book_deal_content .cols_wrapper .col1 { 
  width: 75%; 
}

.book_deal_content .cols_wrapper .col2 {
  width: 25%;
  text-align: center;
}

.book_deal_content .cols_wrapper .col2 img {
  width: 75%;
}


.book_deal_content .cols_wrapper .col1 .benefits { 
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.book_deal_content .cols_wrapper .col2 {
  width: 20%;
}

.book_deal .cols_wrapper { width: 100%; }
.book_deal .cols_wrapper .col { width: 33%; }

.book_deal_content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.book_deal_content ul li:before {
  content: '✓';
  margin: 0 0 0 .5rem;
  font-weight: 800;
}

.book_deal_content .benefits {
  font-size: 0.8rem;
  font-weight: 600;
}

.book_deal .hotel_content  {
  padding: 1rem 1rem 2rem 1rem;
  width: 80%;
  margin: 0 auto;
}

.book_deal .hotel_content  h3 {text-align: right;} 

.book_deal_content .hotel  {
  text-align: right;
}

.dealDetails u {
  color: #cf576f;
}

.book_deal .hotel .cols_wrapper {
  display: flex;
  text-align: justify;
  padding-top: 0.5rem;
  align-items: center;
}
.book_deal .hotel .col.price {flex:1;}
.book_deal .hotel .col.logo {
  flex: 1.8 1;
  margin-left: .5rem;  
  text-align: center;
}

.book_deal .hotel .col.logo img {
  width: 60%;
  float: right;
}

.book_deal .hotel .col.button {flex:2;}

.book_deal .hotel_content button {
  padding: .2em .5em .3em .5em !important;
  font-size: 1.2rem !important;
  min-width: 70%;
}

.book_deal .hotel_content  .img_agoda {
  width: 80%;
  float: right;
}

.book_deal .hotel_content .hotel_title {
  font-size: 90%;
}

.book_deal .hotel_content hr  {
  margin: 1rem 0;
}

.book_deal button {
  background-color: #cf576f;
  color: #fff;
  margin: .15rem 0 !important;
  background-repeat: no-repeat, repeat;
  background-position: right .5em top 50%, 0 0;
  background-size: .65em auto, 100%;
  font-size: 1rem !important;
  font-family: assistant;
  font-weight: 700;
  line-height: 1;
  padding: 0.5em 1.2em 0.5em 1.2em !important;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 25px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
}


footer {
  padding: 1rem 0;
  background: #666;
  color: #fff;
}

footer a, footer a:visited {
  color: #fff;
}

.sidemenu_toggle {display:none;}
.sidemenu_btn {
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  width: 27px;
  padding: 6px;
  margin-right: 1rem;
  margin-left: 2rem;
  /* right: 15px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-content: space-around; */
  /* flex-wrap: wrap; */
  /* align-items: center; */
  height: 18px;
  align-self: center;
}

.sidemenu_btn>span {
  height: 2px;
  width: 100%;
  display: block;
  background-color: #fff;
}

main {
  direction: rtl;
  background: #fff;
  padding: 1rem 0 3rem;
}

.deck {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #61dafb;
  text-decoration: none;
  background: #eee;
  margin: 1rem auto;
  max-width: 1366px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lines {background-color: #fff;}

.lines a {
  text-decoration: none;
  color: inherit;
}

.card {
  color: #555;
  margin:1rem 0.5rem;
  min-width: 300px;
  /*width: 300px;*/
  padding:0;
  filter: drop-shadow(3px 3px 5px #aaa);
}

.card .title.sideTab {
    /*border-left: 0.25rem solid #0ead9a;
    border-right: 0.25rem solid #0ead9a;*/
    background-color: #1e5881;/*#0ead9a ;*/
    padding: 0px 0.25em;
    font-weight: bold;
}

.order_page .card .title.sideTab {
  padding: 0.6rem;
  font-size: 1.3rem;
}


.deck .card > .title a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  padding: 0.5em;
  color: #fff;
align-items: center;
}

.deck  .card > .title a h3 {
margin: 0 0 .1em .1em;
  font-weight: 600;
  font-size: 1.45em;
}

 .subtitle {
    display: flex;
    /*border: 1px solid #999;*/
    padding: 0.75rem;
    justify-content: space-between;
    background: #124b71;
    color: #fff;
    font-weight: 600;
    font-size: initial;
 }

 .subtitle2{
	 display:none;
  padding: 0.75rem;
  justify-content: space-between;
  background: #fff;
  font-weight: 600;
  font-size: 1.7rem;
  display:block;
  font-size: 1.7rem;
  margin-top: 2rem;
 }

 .subtitle2 a {
  color: #cf576f;
  text-decoration: underline;
  font-weight: 800;
  padding-top: 1rem;
  display: inline-block;
  font-size: 1.4rem;
 }

.subtitle  .hours, .seconds, .minutes {
  background-color: #fff;
    color: #cf576f;
    /*border-radius: 10px;*/
    text-align: center;
    /* width: 40px; */
    display: inline-block;
    padding: 0.1rem 0.2rem;
    margin: 0 0.11rem;
    /*font-size: 0.85rem;*/
}

.subtitle button {
  background-color: #cf576f;
    color: #fff;
    border: 1px solid #cf576f;
    /*padding: 0.2rem 0.3rem;*/
    border-radius: 25px;
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    font-weight: 600;
    border-color: #fff;
    font-size: 14px;
    display: inline-block;
}


.card.hotel {cursor: pointer;}
.card.hotel .title.sideTab {
  /*border-left: 0.25rem solid #4fc2f0;
  border-right: 0.25rem solid #4fc2f0;*/
  background-color: #02badb;
  /*#4fc2f0;*/
}
.card > .image {
  height: 195px;
  background-size: cover;
  /*min-width: 330px;*/
  background-position-y: center;
  overflow: hidden;
  cursor: pointer;
}

.card > .image.abu-dhabi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/abu-dhabi.jpg)}
.card > .image.amsterdam {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/amsterdam.jpg)}
.card > .image.antalya {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/antalya.jpg)}
.card > .image.athens {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/athens.jpg)}
.card > .image.ayia-napa {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/ayia-napa.jpg)}
.card > .image.baden-baden {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/baden-baden.jpg)}
.card > .image.baku {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/baku.jpg)}
.card > .image.barcelona {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/barcelona.jpg)}
.card > .image.basel {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/basel.jpg)}
.card > .image.batumi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/batumi.jpg)}
.card > .image.belgrade {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/belgrade.jpg)}
.card > .image.berlin {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/berlin.jpg)}
.card > .image.bologna {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/bologna.jpg)}
.card > .image.bordeaux {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/bordeaux.jpg)}
.card > .image.bratislava {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/bratislava.jpg)}
.card > .image.brussels {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/brussels.jpg)}
.card > .image.bucharest {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/bucharest.jpg)}
.card > .image.budapest {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/budapest.jpg)}
.card > .image.budva {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/budva.jpg)}
.card > .image.burgas {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/burgas.jpg)}
.card > .image.cluj-napoca {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/cluj-napoca.jpg)}
.card > .image.craiova {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/craiova.jpg)}
.card > .image.crete {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/crete.jpg)}
.card > .image.debrecen {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/debrecen.jpg)}
.card > .image.dubai {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/dubai.jpg)}
.card > .image.dubrovnik {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/dubrovnik.jpg)}
.card > .image.eilat {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/eilat.jpg)}
.card > .image.geneva {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/geneva.jpg)}
.card > .image.iasi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/iasi.jpg)}
.card > .image.istanbul {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/istanbul.jpg)}
.card > .image.katowice {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/katowice.jpg)}
.card > .image.kaunas {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/kaunas.jpg)}
.card > .image.kiev {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/kiev.jpg)}
.card > .image.kos {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/kos.jpg)}
.card > .image.krakow {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/krakow.jpg)}
.card > .image.larnaca {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/larnaca.jpg)}
.card > .image.lithuania {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/lithuania.jpg)}
.card > .image.london {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/london.jpg)}
.card > .image.lyon {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/lyon.jpg)}
.card > .image.madrid {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/madrid.jpg)}
.card > .image.manchester {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/manchester.jpg)}
.card > .image.marseille {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/marseille.jpg)}
.card > .image.memmingen {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/memmingen.jpg)}
.card > .image.milan {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/milan.jpg)}
.card > .image.moscow {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/moscow.jpg)}
.card > .image.nantes {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/nantes.jpg)}
.card > .image.naples {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/naples.jpg)}
.card > .image.nice {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/nice.jpg)}
.card > .image.nuremberg {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/nuremberg.jpg)}
.card > .image.paphos {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/paphos.jpg)}
.card > .image.paris {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/paris.jpg)}
.card > .image.poznan {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/poznan.jpg)}
.card > .image.prague {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/prague.jpg)}
.card > .image.rhodes {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/rhodes.jpg)}
.card > .image.riga {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/riga.jpg)}
.card > .image.rome {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/rome.jpg)}
.card > .image.sibiu {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/sibiu.jpg)}
.card > .image.sofia {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/sofia.jpg)}
.card > .image.tbilisi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/tbilisi.jpg)}
.card > .image.thessaloniki {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/thessaloniki.jpg)}
.card > .image.timisoara {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/timisoara.jpg)}
.card > .image.tivat {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/tivat.jpg)}
.card > .image.toulouse {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/toulouse.jpg)}
.card > .image.varna {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/varna.jpg)}
.card > .image.venice {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/venice.jpg)}
.card > .image.vienna {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/vienna.jpg)}
.card > .image.vilnius {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/vilnius.jpg)}
.card > .image.warsaw {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/warsaw.jpg)}
.card > .image.zagreb {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/zagreb.jpg)}
.card > .image.limassol {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/limassol.jpg)}
.card > .image.izmir {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/izmir.jpg)}
.card > .image.suceava {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/suceava.jpg)}
.card > .image.turin {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/turin.jpg)}
.card > .image.kusadasi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/kusadasi.jpg)}
.card > .image.sharm-el-sheikh {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/sharm-el-sheikh.jpg)}
.card > .image.catania {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/catania.jpg)}
.card > .image.corfu {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/corfu.jpg)}
.card > .image.st-pauls-bay {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/st-pauls-bay.jpg)}
.card > .image.protaras {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/protaras.jpg)}
.card > .image.nicosia {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/nicosia.jpg)}
.card > .image.albena {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/albena.jpg)}
.card > .image.euro-disney {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/euro-disney.jpg)}
.card > .image.athens-riviera {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/athens-riviera.jpg)}
.card > .image.halkidiki {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/halkidiki.jpg)}
.card > .image.kyrenia {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/kyrenia.jpg)}
.card > .image.bari {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/bari.jpg)}
.card > .image.chisinau {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/chisinau.jpg)}





.card img {
  width:100%;
}

.card > .title {
  background: #1f5981;
  color: #fff;
  cursor: pointer;
  /*position: absolute;*/
}

.card > .title > h3 {
  margin:0;
  padding:0.5em;
  font-size: 1.3rem;
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
}

.card p {
  margin: 0;
}

.card > .lines {
  display:flex;
  flex-direction: column;
  flex: 1;
/*  height: 475px;
  overflow-y: auto;
  overflow-x: hidden;*/
}

.lines::-webkit-scrollbar {
  width: 0.5em;
}

.lines::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.lines::-webkit-scrollbar-thumb {
background-color: darkgrey;
outline: 1px solid slategrey;
}

.card > .showMore {
  background-color: #1e5881;
  line-height: 3rem;
  font-weight: 700;
  color: #fff;/*#0ead9a;*/
  cursor: pointer; 
}

.card > .showMore i {
  vertical-align: text-top;
}

.line {
  display:flex;
  flex-direction: row;
  flex: 1;
  /*padding: 0.5rem 0.5rem 1rem;*/
  border-top: 1px solid #ccc;
  cursor: pointer;
}

.lineWrapper:hover {
  /*filter: drop-shadow(0px 0px 1px #aaa);*/
  background: #f5f5f5;
}

.line > .dates {
  flex-grow: 3; 
  text-align: right;
  margin: 0.5rem 0.5rem 1rem;
}

.line > .dates > .caption {
  margin-top: 0.5rem;
  padding: 0.3rem 0.5rem;
  width: fit-content;
  font-size: 0.8rem;
  font-weight: 600;
  color: #000;
}

.line > .dates > .captionHighlight {
  margin-top: 0.5rem;
  padding: 0.3rem 0.5rem;
  width: fit-content;
  font-size: 0.8rem;
  background-color: #fff68f;
  /*background-color: #feed35;*/
  font-weight: 600;
  color: #000;
}

.line > .dates > .caption > .icon {
  display:inline-block;
  margin:0 0 0 0.2rem;
}

.line > .dates > .caption > .text {
  display:inline-block;
}
.line > .dates .caption > span{
  font-weight: bold;
  font-size: 0.9rem;
  color: #124b71;
  display: block;
}

.line > .dates > .range {
  font-size: 1.1rem;
  line-height: 2rem;
}

.line > .price > .nights {
  color: #1f5981;
  font-size: 0.9rem;
}

.line > .price .from {
  font-size: 0.7rem;
}

.line > .price .note {
  display: block;
  color:#0cb7d7;
  font-weight: 700;
}

.line > .price > .nights > .count {
  margin: 0 0.3rem;
  font-weight: 700;
}

.line > .dates > .symbols > .direct-flight {
  font-size: 0.8rem;
  margin-right: 0.5rem;
  color: #24b3a2;
  font-weight: 700;
}

.card .line.locked > .price {display:none;}

.card.locked .line.unlocked > .price > .amount {
  /*font-weight: 700;
  color: #000;*/
}

.card .line > .members_lock {display:none;}

.card .line.locked > .overlay {
  background: url(/images/cardline_overlay.png);
  width: 100%;
  height: 108px;
  display: block;
  position: absolute;
  opacity: .1;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.card .line.expired > .overlay {
  background: url(/images/cardline_overlay.png);
  width: 100%;
  height: 135px;
  display: block;
  position: absolute;
  opacity: .3;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.card .line.expired > .expired {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1000;
  font-weight: 800;
  font-size: 24px;
  color: white;
  position: absolute;
  rotate: 18deg;
  margin: 2em 3.5em 2em 0;
  background: #510202;
  padding: 0 1em;
  direction: rtl;
}



.line.locked > .dates {
  opacity: .4;
}

.card .line.locked > .members_lock {
  display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0.5rem;
    z-index: 1000;
}

.card .line > .members_lock > .caption {
  font-size: 0.85rem;
    font-weight: 700;
    color: #333;
}

.card .line > .members_lock > .btn button {
  background-color: #f0d77f;
  border-radius: 5px;
  border: none;
  font-weight: 700;
  border: none;
  padding: 0.2rem 0.5rem;
  margin-top: 0.4rem;
}

.line > .price {
  font-weight: 600;
  flex-grow: 1;  
  align-content: flex-start;
  margin: 0.5rem 0.5rem 1rem;
}

.line > .price > .amount {
  font-size: 2rem;
  line-height: 1rem;
  padding: 0.5rem 0 0;
  color: #666;
}

.line > .price > .amountCaption {
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
}

.line > .price > .caption {
  font-size:0.8rem;
}


.hotel_content {
  direction: ltr;
  min-height: 120px;
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  font-weight: normal;
  text-align: left;
  max-width: 300px;
}



.order_page .hotel_content {
  direction: ltr;
  min-height: 120px;
  width: 100%;
  padding: 0.5rem 3.5rem 0.5rem 3.5rem;
  font-weight: normal;
  text-align: left;
}
.hotel_image {
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  margin: 0.5rem;
  height: 110px;
  float: left;
  width: 110px;
}

.hotel_details {
  padding: 0;
  text-align: right;
  font-weight: normal;
  font-size: 0.8rem;
}

.hotel_details div {
  margin: 0 0 0.1rem 0;
}

.hotel_title {
  font-weight: 600;
  font-size: 1.2em;
  margin:0 0.5em;
}

.hotel_title .stars {
  display:block;
}

.hotel_details .rating {
  background-color: limegreen;
  border-radius: 10px;
  color: #fff;
  width: 40px;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
}

.fa-star:before {
  content: "\f005";
  color: #e4c106;
}


.deal_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: .9rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    font-weight: 600;
}

.route {
  clear: both;
  display: flex;
  place-content: center;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  cursor: pointer;
}

.order_page .deal_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1.2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    font-weight: 600;
}

.deal_content .from,.deal_content .to {width: 33%;}

.deal_content .hotel_price {
  font-weight: 600;
  font-size: 1.2rem;
  color: #000;
  text-align: right;
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.deal_content .airline {
  text-align: right;
  padding: .5rem .5rem 0 0;
  font-size: .8rem;
}

.order_page .deal_content .airline {
  text-align: right;
  padding: 0.5rem 0.5rem 0 0;
  font-size: 0.8rem;
}

.order_page .route {
  clear: both;
  display: flex;
  place-content: center;
  justify-content: space-between;
  padding: 1rem 3.5rem;
  cursor: pointer;
}

.duration { 
  font-size: .8rem;
  width: 34%;
}

.order_page .duration {
  font-size: 1.1rem;
  width: 34%;
}

.duration .duration_caption {
  border-bottom: 1px solid #000;
  font-size: 0.9em;
} 

.duration .duration_comment {color: #1e5881;}/* #0ead9a;}*/

.flight_content hr {
  margin: 0.5rem 0;
}

.flight_content .from {text-align:right;min-width:30%;}
.flight_content .to {text-align:left;min-width:30%;}

.flight_content .time {
  font-weight: 600;
  display: inline-block;
  font-size: 1.2rem;
}

.filters select {
  font-family: assistant;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 2em .5em 1.2em;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 0 0 1em;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #1e5881; /* #0ead9a;*/
  /*background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3…c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E);*/
  /* background-image: url(data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' w…svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>); */
  background-repeat: no-repeat, repeat;
  background-position: right .5em top 50%, 0 0;
  background-size: .65em auto, 100%;
  font-size: 1.2rem;
  color: #fff;
}

select {
  font-family: assistant;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 2em .5em 1.2em;
  max-width: 100%; 
  box-sizing: border-box;
  margin: 0 1em;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 2em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #00abc9;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  /*background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");    */
  background-repeat: no-repeat, repeat;
  background-position: right .5em top 50%, 0 0;
  background-size: .65em auto, 100%;
  font-size: 1rem;
  color: #fff;
}
select::-ms-expand {
  display: none;
}
select:hover {
  border-color: #888;
}
select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .3);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  outline: none;
}
select option {
  font-weight:normal;
}


.filters {
  display: flex;
  justify-content: center;
  margin: 0 auto 0.5rem;
  flex-wrap: wrap;
}

.filters label {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  text-align: right;
  font-size: 1.2rem;
  align-self: center;
  padding-bottom: 1rem;  
}


select.filterOrigin {
  /*background-color: #17a2b8;*/
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22384%22%20height%3D%22512%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M172.268%20501.67C26.97%20291.031%200%20269.413%200%20192%200%2085.961%2085.961%200%20192%200s192%2085.961%20192%20192c0%2077.413-26.97%2099.031-172.268%20309.67-9.535%2013.774-29.93%2013.773-39.464%200zM192%20272c44.183%200%2080-35.817%2080-80s-35.817-80-80-80-80%2035.817-80%2080%2035.817%2080%2080%2080z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  fill: #fff;
}

select.filterWhen {
  /*background-color: #faa135;*/
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22512%22%20height%3D%22512%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M256%208C119%208%208%20119%208%20256s111%20248%20248%20248%20248-111%20248-248S393%208%20256%208zm57.1%20350.1L224.9%20294c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6%205.4-12%2012-12h48c6.6%200%2012%205.4%2012%2012v137.7l63.5%2046.2c5.4%203.9%206.5%2011.4%202.6%2016.8l-28.2%2038.8c-3.9%205.3-11.4%206.5-16.8%202.6z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  max-width: 170px;
}

select.filterRating {
  /*background-color: #28a745;*/
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22512%22%20height%3D%22512%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M173.898%20439.404l-166.4-166.4c-9.997-9.997-9.997-26.206%200-36.204l36.203-36.204c9.997-9.998%2026.207-9.998%2036.204%200L192%20312.69%20432.095%2072.596c9.997-9.997%2026.207-9.997%2036.204%200l36.203%2036.204c9.997%209.997%209.997%2026.206%200%2036.204l-294.4%20294.401c-9.998%209.997-26.207%209.997-36.204-.001z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
}


select.filterStars {
  padding-left: 1.5rem;
/*  background-color: #dc3545;*/
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
}


select.filterNights {
  /*background-color: #743c90;*/
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22512%22%20height%3D%22512%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M283.211%20512c78.962%200%20151.079-35.925%20198.857-94.792%207.068-8.708-.639-21.43-11.562-19.35-124.203%2023.654-238.262-71.576-238.262-196.954%200-72.222%2038.662-138.635%20101.498-174.394%209.686-5.512%207.25-20.197-3.756-22.23A258.156%20258.156%200%200%200%20283.211%200c-141.309%200-256%20114.511-256%20256%200%20141.309%20114.511%20256%20256%20256z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
}

select.filterDates {
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22448%22%20height%3D%22512%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M148%20288h-40c-6.6%200-12-5.4-12-12v-40c0-6.6%205.4-12%2012-12h40c6.6%200%2012%205.4%2012%2012v40c0%206.6-5.4%2012-12%2012zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6%200-12%205.4-12%2012v40c0%206.6%205.4%2012%2012%2012h40c6.6%200%2012-5.4%2012-12zm96%200v-40c0-6.6-5.4-12-12-12h-40c-6.6%200-12%205.4-12%2012v40c0%206.6%205.4%2012%2012%2012h40c6.6%200%2012-5.4%2012-12zm-96%2096v-40c0-6.6-5.4-12-12-12h-40c-6.6%200-12%205.4-12%2012v40c0%206.6%205.4%2012%2012%2012h40c6.6%200%2012-5.4%2012-12zm-96%200v-40c0-6.6-5.4-12-12-12h-40c-6.6%200-12%205.4-12%2012v40c0%206.6%205.4%2012%2012%2012h40c6.6%200%2012-5.4%2012-12zm192%200v-40c0-6.6-5.4-12-12-12h-40c-6.6%200-12%205.4-12%2012v40c0%206.6%205.4%2012%2012%2012h40c6.6%200%2012-5.4%2012-12zm96-260v352c0%2026.5-21.5%2048-48%2048H48c-26.5%200-48-21.5-48-48V112c0-26.5%2021.5-48%2048-48h48V12c0-6.6%205.4-12%2012-12h40c6.6%200%2012%205.4%2012%2012v52h128V12c0-6.6%205.4-12%2012-12h40c6.6%200%2012%205.4%2012%2012v52h48c26.5%200%2048%2021.5%2048%2048zm-48%20346V160H48v298c0%203.3%202.7%206%206%206h340c3.3%200%206-2.7%206-6z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
}

.filters .btn.search {
  background-color: #cf576f;
  color: #fff;
  background-repeat: no-repeat, repeat;
  background-position: right .5em top 50%, 0 0;
  background-size: .65em auto, 100%;
  font-size: 1.2rem;
  font-family: assistant;
  font-weight: 700;
  line-height: 1.3;
  padding: .6em 2em .5em 1.2em;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 0 0 1em;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.btn.promotions.exit {
  background: #01bddd;
  min-width: 15%;
  color: #fff;
  font-size: 1.4rem;
  /* border: 1px solid #aaa; */
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 4%);
  border-radius: 5px;
  padding: .5em 3em;
  border-radius: 10px;
  font-weight: 800;
}

.promotionBillboard {
  max-width: 1316px;
  min-height: 250px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: contain;
}

.promotionBillboard.Max {
  background-image:url(/images/promotions/max199/desktop-billboard.jpg)
}

.search_wrapper {
  display: flex;
  flex-direction: column-reverse;
  margin: 0em auto;
  max-width: 1316px;
}

.filters_toggle {
  display:none;
}

.dealDetails .card {
  filter: none;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;  
  background: #fff;
}

.dealDetails .airline img {
  width: 24px;
  height: 24px;
  padding: 0;
}

.order_page .dealDetails .airline img {
  width:40px;
  height:40px;
  padding:0;
}

.dealDetails .airline img.hide {
  display:none;
}

.dealDetails .title {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.dealDetails > .overlay {
  display:none;
}

.dealDetails > .overlay .wrapper {
  position: absolute;
  z-index: 1000;
  width: 100%;
  background: #000;
  opacity: 0.4;
  min-height: 310px;
}

.dealDetails {
  display:none;
  max-width: 350px;
}

.dealDetails.active {
  display: block!important;
  /*width: 95%;*/
  margin: 0 auto;
  border: 1px solid #ddd;
  max-width: 350px;
}

.lineWrapper.active {
  color: #000;
  font-weight: 600;
  /*background-color: #f5f5f5;*/
}

.order.active {display:block;}

.order {
  padding: .5rem 0;
  display:none;
}

.order  button {
  margin: 0 auto 1em;
  text-align:center;
  width: 95%;
  font-weight: bold;
  height: 8%;
  font-family: assistant;
  font-size: 1rem;
  color: #000;
  background-color: #fed102;
  border-radius: 5px;
  border:none;
  padding: 0;
  min-height: 45px;
  line-height: 1.2;

 
}
.dealDetails > .overlay button {
  position:absolute;
  z-index:1001;
  position: absolute;
  z-index: 1001;
  margin: 0 auto;
  width: 50%;
  font-weight: bold;
  height: 5%;
  font-family: assistant;
  border: 1px solid #999;
  margin: 40% -25%;
  color:#000;
  background-color: gold;
}

.dealDetails > .overlay button:hover { 
  background-color: #ddbb05;
  color: #222;

}


.flight .symbols.daysrange{ 
  padding-top:0;
}

.flight .symbols {
  padding-top: 0.5rem;
  padding-bottom: 0;
}

.symbols {
  border-top: none;
  padding: 0 0 0 0.5rem;
  text-align: right;
  display: flex;
  color: #ddd;
  /*padding-bottom: 1rem;*/
}

.symbols .icons {
  font-size:0.9rem; 
  color: #666;
  margin-right:0.5rem;
}


.weekend, .holiday {
  border-radius: 25px;
  color: #fff;
  padding: 0.1rem 0.4rem;
  margin: 0 0.4rem 0 0;
  font-size: 0.75rem;
}

.weekend {
  background: #02badb;/*#0ead9a;*/
  border: 1px solid #02badb; /* #0ead9a;*/
}

.holiday {
  background: #cf576f;
  border: 1px solid #cf576f;
}


.vacation {
  background: #02badb; /* #0b9fc1;*/
  border: 1px solid #02badb;/* #12789b;*/
  border-radius: 25px;
  color: #fff;
  padding: 0.1rem 0.4rem;
  margin: 0 0.4rem 0 0;
  font-size: .75rem;
}

.weekend.hide, .holiday.hide {display:none;}

.days {
  padding: 0.1rem 0.4rem;
  background: none;
  color: #777;
  font-size: 0.8rem;
  border: 1px solid #ccc;
  background-color: #fff;
}


.order_details .comment {color: #aaa;
font-size: .9rem;}
.order_wrapper .totals {
  display: table;
  margin: 0 auto;
  width: 100%;
  border: 1px inset #aaa;
  text-align: center;
}

.order_wrapper .totals .middle {
  border-right-color: #ddd;
  border-right-style: solid;
  border-left-style: solid;
  border-left-color: #ddd;
  border-width: 2px;
}

.order_wrapper .title h1 {
  margin:0;
  font-size:2rem;
}

.order_wrapper .title {
  margin-bottom:2rem;
  text-align:right;
}

.order_wrapper .title .dates {
  display:inline;
}

.order_wrapper .row.head {
  background: #1e5881; /* #0ead9a;*/
  color: #fff;
  font-size:1.5rem;
  font-weight:800;
  display:table-header-group;
}

.order_wrapper .row.head div {
  display: table-cell;
  width: 33%;
  padding: 1rem;
}

.order_wrapper .middleCol {
  border-left: 0.1rem solid #fff;
  border-right: 0.1rem solid #fff;

}

.order_wrapper .row.content {
  display: table-row;
  background: #fafafa;
}

.order_wrapper .row.content > div {
  display: table-cell;
  width: 33%;
}

.order_wrapper .row.content .price {
  color:#cf576f;
  font-size: 2.5rem;
  margin-top:10px;
}

.order_wrapper .row.content .price.small {
  font-size: 1.5rem;
}

.order_wrapper .row.content > div > div {
  font-size: 1.3rem;
  font-weight: 600;
  color: #666;
}

.order_wrapper .greybg {
  background: #f0f0f0;
}

.order_wrapper .comment {
    padding: 0.5rem 0 1rem;
}

.order_page {
  text-align:right;
  width:55%;
  margin:0 auto;
}

.order_page .main_title {
  background: #124b71;
  color: #fff;
  text-align: center;
  padding: .05rem 0;
  /*border-bottom: 1px solid #999;*/
}

.order_page .sideTab {
  text-align: center;
  margin:0;
}

.order_page .main_title > h1 {
  font-size: 2rem !important;
}

.order_page .main_title  .dates { 
  font-weight: 400 !important;
  font-size: 1.8rem;
}

.order_page .main_title .icon {  
  margin: 0 .65rem;
  padding: 0;
  display: inline-block;
  transform:rotate(180deg);
  font-size: .95rem;
}

.order_page .deal_content {
  padding: 1rem 0;
} 

.full_warning {
  font-size: 1.4rem;
} 

.full_warning button {
  width: 20% !important;
  margin-top: 1rem !important;
  padding: 1rem 0 !important;
}


 .booking_warning {
  font-size: 1.35rem;
}

.booking_warning button {
  width: 90% !important;
  margin-top: 1rem !important;
  padding: 1rem 0 !important;
}

.order_page .dealDetails {
  display: block !important;
  border: 1px solid #ccc;
}

.order_page .dealDetails .card.flight { 
  text-align: center;
}

.order_page .dealDetails .card.hotel .hotel_title { 
  text-align: left;
  margin: 0 0 0.5rem;
  font-size: 1.4rem;
}

.order_page .dealDetails .card.hotel .hotel_title h3 {  
  font-size: 1.6rem;
}

.order_page .dealDetails .card.hotel .hotel_details { 
  text-align: right;
  font-size: 1.3rem;
  margin: 0 0.5rem;
  padding: 0;
}

.order_page .dealDetails .card.hotel .hotel_details .stars {
  display: initial;
}

.order_page .dealDetails .card.hotel .hotel_details .rating {
  font-size: 1.1rem;
  margin: 0.1rem 0;
}

.order_page .dealDetails .card.hotel .hotel_details .div_breakfast, .order_page .dealDetails .card.hotel .hotel_details .div_wifi {
  margin: 0.3rem 0;
}

.order_page .dealDetails .card.hotel .hotel_details .div_center .icon {
  display:inline-block;
  padding: 0.1rem 0.2rem 0 0 ;
  line-height: 0.5rem;
}

.order_page .dealDetails .card.hotel .hotel_image {
  width: 200px; 
  height: 200px;
}

.order_page .deal_total {
  border-top: 1px solid #999;
  padding: 0.7rem 0.5rem;
  background-color: #fff;
  font-size: 2rem;
  padding: 1rem 2rem;
}

.order_page .deal_total h3, .order_page .deal_total h2 {
  padding: 0;
  margin: 0;
  color: #cf576f;
  display: inline-block;
}

.order_page .deal_total .price {
  float: left;
}

.deal_content .bags button { 
  text-decoration: underline; 
  color: #777;
  border: none;
  background: inherit;
  padding: 0 0.1rem;
}

.deal_content .bags {
  border: 1px solid #555;
  width: 90%;
  margin: 0.1rem auto 0;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.1rem;

}

.order_page .dealDetails .bags  {
  border: 1px solid #555;
  width: 67%;
  margin: 2rem 4rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 3.5rem;
}


.order_page .warning-top {
  font-size: 1.2rem;
  padding: .5rem 1rem;
  text-align: center;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  color: #555;
  background: #fff;
}

.order_page .warning2 {
  margin: 0.5rem 0rem 1rem;
  font-size: 0.75rem;
  border:1px dashed #999;
  padding: 1rem;
}

.order_page .header_warning {
  text-align: center;
  font-size: 1.1rem;
  background: #cf576f;
  color: #fff;
  padding: 1rem 0.5rem;
  font-weight: 600;
}

.order_page .comment {
  color: #666;
  font-size: 1.2rem;
  text-align:  center;
}

.order_page .deal_total h2  { 
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1;
}

.order_page .buttons {
  margin: .5rem 0;
  min-width: 100%;
  display: flex;
  flex-direction: row;
}

.order_page .buttons button, .booking_warning button, .full_warning button {
  padding: 0;
  margin: 0;
  background-repeat: no-repeat, repeat;
  background-position: right .5em top 50%, 0 0;
  background-size: .65em auto, 100%;
  font-size: 1.05rem;
  font-family: assistant;
  font-weight: 700;
  line-height: 1;
  padding: .3em 0.9em .5em 0.9em;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
}

.order_page .buttons .next, .booking_warning button {
  margin: 0 0.3rem 0 0;
  color: #fff;
  background-color: #cf576f;
  width: 60%;
  font-size: 1.15rem;
}

.order_page .buttons .back {
  color: #fff;
  background-color: #aaa;
  padding: 0.8rem 0.5rem;
  margin: 0 auto;
  width: 40%;
}

.order_page_2 .buttons .back {
  padding: .7rem 1rem;
  font-size: 1.1rem;
}
.order_details .warning h2 {
  margin-bottom:0;
  font-size: 1.6rem;
  font-weight: 800;
}

.order_details .warning {
  font-size: 1.3rem;
  width: 95%;
  margin: 2rem auto 4rem;
  font-weight:700;
  color: #cf576f;
  text-align: center;
}

.order_details .warning.border {
  border:2px solid #555;
  padding: 1.4rem;
  margin: 2rem auto;

}

.order_details .box {
  display:flex;
  border:1px solid #eee;
  background-color: #f5f5f5;
  padding:1.5rem 1rem;
}

.order_details .box > div {
  display:flex;
  flex: 1;
}

.order_details .box > .first_col { 
  font-size:1.5rem;
  font-weight: 700;
  color: #1e5881;/* #0ead9a;*/
  flex-grow: 1;
  justify-content: center;
  display: block;
  text-align: center;
}

.order_details .box  .first_col p img  { 
  margin-top:20%;
  height: 40px;
}

.order_details .box.hotel  .first_col p img  { 
  height: 50px;
}

.hotel  .center_col .photo {
  display:block;
}


.hotel_wrapper .photo {
  display:none;
}

.box.hotel .btn_col img.booking {
  max-width: 90%;
}

.box.hotel .btn_col img.agoda {
  max-width: 40%;
}

.btn_col .main_price {
  font-size: 1.3rem;
  font-weight: 600;
  color: #666;
  margin: 1rem 0;
}

.btn_col .main_price > .price {
  color: #cf576f;
  font-size: 2.5rem;
  margin-top: 10px;
}



.order_details .box > .center_col {
  flex-grow: 5;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 0 2rem;
  flex-direction: column;
  font-size: 0.8rem;
}

.order_details .box > .center_col h3 { 
  margin-top:0;
  font-size: 1.3rem;
}

.order_details .box > .btn_col {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 2;
    padding-right: 1rem;
}

.order_details .flight ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order_details .flight  ul li:before {
  content: '✓';
  margin: 0 0 0 0.3rem;
  font-weight: 800;
}
.order_details button {
  border-radius: 0.5rem;
  padding: 0.1rem 2rem;
  font-size: 1.3rem;
  font-weight: 700;
  font-family: assistant;
  border: 1px solid #ccc;
  background-color: #ffd066;
  margin-top: 0.3rem;
}

.order_details hr {
    width: 90%;
    border: none;
    border-top: 1px solid #ddd;
    margin: 1rem;
}

.order_details .flight .title {
  color: #1e5881; /* #0ead9a;*/
}

.order_details .flight button {
  background-color: #1e5881;/* #0ead9a;*/
  color: #fff;
  border: none;
  margin-top: 1.5rem;
}

.order_details .hotel button {
  background-color: #cf576f;
  color: #fff;
  border: none;
}


.order_details .hotel .title, .order_details .hotel .first_col {
  color: #cf576f;
}

.flight .comment {
  color: #aaa;
  font-size: 0.8rem;
}

.flight_details {
      width: 80%;
      display: flex;
      flex: 1;
      flex-direction: row;
}

.flight_details .left {
  min-width: 20%;
  max-width: 20%;  
  text-align: left;
}
.flight_details  .right {
  min-width: 20%;
  max-width: 20%;
  text-align: right;
}


/*
.flight_details .right.to:before {
    border-left: 2px solid #999999;
    border-top: 2px solid #999;
    width: 5px;
    content: '';
    height: 5px;
    transform: rotate(-45deg);
    float: right;
    position: relative;
    top: 0.96rem;
    left: 0.9rem;
    margin: 0 0.3rem;
}

.flight_details .left.to:after {
  border-left: 2px solid #999999;
  border-top: 2px solid #999;
  width: 5px;
  content: '';
  height: 5px;
  transform: rotate(-225deg);
  float: right;
  position: absolute;
  margin: -2px 4px;
}
*/

.flight_details .line {
  flex-grow: 1;
  border-top: none;
  border-bottom: 3px solid #999;
  align-self: flex-start;
  justify-content: center;
  margin: 0 1rem;
  font-size: 0.8rem;
  padding:0;
  max-width: 45%;
  min-width: 45%;
}

.airline_logo {
  margin:0.3rem 1rem;
}

.hotel .content{
  width: 50%;
  text-align: left;
}

.hotel .center_col {
  flex-direction: row !important;
  justify-content: space-around;
}

.hotel .stars {
  color:goldenrod;
  font-size: 1.3rem !important;
  display: inline-block;
}

.hotel .div_breakfast {
  padding: 0.2rem 0 0 0;
  font-weight: bold;
  color: #02badb;
}

.hotel .div_center {
  margin: 0.4rem 0;
}

.hotel_wrapper {
    display:flex;
    flex-direction: column;
    border: 1px solid #eee;
    background-color: #f5f5f5;
    text-align: center;
    margin-top: 2rem;
 }

.hotel_wrapper .photo img  {
    max-width: 90%;
    margin: 1rem auto 0;
    border-radius: 10px;
}

.hotel .rating {
  background-color: #1e5881;/* #0ead9a;*/
  color: #fff;
  border-radius: 10px;
  text-align: center;
  width: 40px;
  display: inline-block;
  margin: 0 0.2rem 0.2rem 0;
}

.mobile_only {
  display:none;
}

.hide {
  display:none !important;
}

.hotel .photo {
  flex: 1;
}

.hotel .benefits > div {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

div.div_hr.black {
  background: #999;
  width: 28%;
}

div.div_hr.black:before  {
  border-color: #999 transparent;
}

div.div_hr {
  width:50%;
  height: 1px;
  background: #cf576f;
  position:relative;
  margin:15px auto 30px;
  -webkit-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  -ms-transform:rotate(180deg);
}
div.div_hr:after {
  content:'';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -13px;
  left: 47.5%;
}
div.div_hr:before {
  content:'';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #cf576f transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -14px;
  left: 47.5%;
}

.headerImg.main {display:none;}

.headerImg_mobile.main {
  display:table;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: unset;
  background: #1eb9cb;
  width: 100%;
  min-height: inherit;
  padding: 3.5rem 0.5rem 2rem;
  height: inherit;
  margin-top: 2rem;
}

.headerImg_mobile .slogan {
  line-height: 3.2rem;
  margin: 0 auto;
  padding: 0;
  
  letter-spacing: 0;
  max-width: 50%;
  font-weight: 600;
  -webkit-text-stroke: none;
  color: white;
  text-shadow: none !important;
  font-size: 1.5rem;
  }

.headerImg_mobile img { 
  width: 35%;
  position: relative;
  /*margin: -1rem -12rem;*/
  float: right;
  margin: -1rem 0;
}

.headerImg_mobile .first_heading {
  /*letter-spacing: 0.13rem;*/
  margin: 0;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 1.2rem;
}

.headerImg_mobile .second_heading {
  font-weight: 400;
  font-size: 3.2rem;
  margin: 1rem 0;
  letter-spacing: -2px;
}

.headerImg_mobile  button {
  background-color: #cf576f;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #fff;
  /* width: 100%; */
  margin-top: 10px;
  padding: 0.3rem 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  
}

.modal_content h2 {
  color: #cf576f;
  margin-bottom: 0;
}

.App .partners {
  color: #666;
  font-size: 1.5rem;
  margin: 3rem auto 1rem;
  font-weight: bold;
  width: 45%;
}

.App .partners .logos {
  display: flex;
    /* align-content: space-around; */
    justify-content: space-between;
    width: 75%;
    margin: 0 auto;
}

.App .partners img.agoda {
  height: 50px;
}

.App .partners img.kiwi {
  height: 60px;
  padding: 0 2rem;
}

.App .partners img.booking {
  width: 185px;
}

/**** VIP ***/
.App.Members, .App.MemberSignup  .btns.disabled button{ 
  /*opacity: .6;*/
}

.App.MemberSignup  .btns .overlay {
    width: 100%;
    height: 160px;
    position: absolute;
    margin-top: -70px;
    z-index:1000;
    display: block;
}

.App.MemberSignup  .btns .overlay.disabled {
  display:none;
}

.App.Members,.App.Members Main, .App.MemberSignup {
  font-family: assistant;
  color: #124b71;
}

.App.Members,.App.Members Main a {
  color: #124b71;
}

.App.Terms Main, .App.MemberTerms Main, .App.About Main , .App.Media Main , .App.About Main  .App.Members Main{ 
  margin-top: 40px;
}

.App.Members h1, .App.MemberSignup h1 {
  color: #deb20b;
  margin: 0;
  font-weight: 800;
}

.App.Members h2, .App.MemberSignup h2 {
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin:0 0 0.5rem;
}

.App.Members .gold, .App.MemberSignup .gold {
  color: #deb20b;
}

.App.Members .cards { 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 800px;
  margin: 0 auto;
}

.App.Members .card {
  color: #124b71;
  filter: none;
  width: 45%;
}

.App.Members .icon {
  color: #555;
  margin-top: 2.5rem;
}

.App.Members h3 {
  margin: 0.3rem 0 0;
}

.App.Members .btns {
  margin: 2rem auto;
}

.App.Members .btns button{
  font-size: 1.5rem;
  border: none;
  border-radius: 10px;
  margin:1rem ;
  padding: 0.5rem 2rem;
  text-align: center;
  font-family: assistant;
  font-weight: 700;
  cursor: pointer;
}

.App.Members .btns a {
  text-decoration: none;
}

.App.Members .btns .signup{ 
  background-color: #deb20b;
  color: #000;
}

.App.Members .btns .signin{ 
  background-color: #1e5881;/* #0ead9a;*/
  color: #fff;
}

.App.MemberSignup .btns {
  margin: 2rem;
}

.App.MemberSignup .facebook-login-btn {
  border-radius: 10px;
  border: none;
  padding: 1rem 1rem;
  font-size: 1.3rem;
  font-family: assistant;
  color: #fff;
  background-color: royalblue;
  direction: ltr;
  font-weight: 600;
  min-width: 275px;
}

.App.MemberSignup .google-login-btn {
  border-radius: 10px !important;
  padding: 0.4rem 0.5rem !important;
  font-size: 1.3rem !important;
  color: #000;
  border-color: #999 !important;
  border-width: 2px !important;
  direction: ltr;  
  font-family: assistant !important;
  min-width: 275px;
  justify-content: space-evenly;
}

.App.MemberSignup .google-login-btn span {
  font-weight: 700 !important;
}

.App.MemberSignup .google-login-btn div {
  background: inherit !important;
}

.App.MemberSignup .facebook-login-btn i {
  padding: 0 0.5rem 0 0;
    font-size: 1.5rem;
    color: #fff;
}

.App.Contact iframe {
  max-width: 600px !important;
}

.App.Contact h1 {margin-top:5%;}


.checkbox input {
  position: absolute;
  opacity: 0;
}

.checkbox .overlay {
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 8px;
  border: 3px solid #deb20b;
  transform: opacity 1s linear;
  transition: all 0.3s;
  display: inline-block;
  margin: 0 0 -10px 10px;
}

.checkbox .label {
  margin: 0 2.5rem 0rem 0rem;
}

.checkbox .icon {
  color: white;
  display: none;
}

.checkbox input:checked ~ .overlay {
  background-color: #deb20b;
  border-radius: 8px;
  opacity: 1;
  border: 3px solid #deb20b;
}

.checkbox input:checked ~ .overlay .icon {
  display: block;
}


.carousel {
  background: #14bae3;
}

.carousel p {
  color: #fff;
    font-size: 4rem;
    margin: 0;
    padding: 0;
}

.carousel img { 
  width: 80%;
}

.carousel .slogan {
  margin-top: -10%;
  padding-bottom: 3rem;
}

.carousel .slogan img {
  width: 17%;
  margin: -2rem 0rem -2.7rem 0;
}

.special-icon {
  display: none !important;
}

span.special-icon img {
  width: 50px;
  position: absolute;
  margin-right: 0.5rem;
}

.special-icon.black-friday {  
  float: left;
  margin-left: -65px;
  margin-top: -52px;
  transform: rotate(-9deg);
}

footer .copyrights {display:inline-block;}
footer .copyrights-seperator {display:inline-block;padding: 0.2rem}


/* FAQ */

section.cd-faq {
  width: 75%;
  margin: 0 auto;
}

.cd-faq__items h2 {
  margin: 1rem 0;
  font-size: 1.65rem;
  
}

ul.cd-faq__categories {
  list-style: none;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 2rem auto;
  padding: 0;
}

ul.cd-faq__categories li {
  margin: .5rem auto;
  border-radius: 5rem;
  background-color: #11799c;
  padding: 0.5rem 0rem;
  width: 30%;
}

.cd-faq__categories a{
  color: #fff;
  text-decoration: none;
}

.cd-faq__group {
  list-style: none;
  padding: 0;
  margin: 2rem;
  text-align: right;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 2rem;
}

a.cd-faq__trigger {
  text-decoration: none;
  color: #11799c;
  font-size: 1.2rem;
  font-weight: 600;
  top: -100px;
}

.cd-faq__item {
  position: relative;
}

.cd-faq__item_anchor {
  position: relative;
  top: -100px;
  visibility: hidden;
}

.cd-faq__item img {
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
}

span.cd-faq__anchor{
  display:block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

div#afnu_filters_wrapper {
  width: 100%;
  padding: 0 1.5em 1.5em;
  /*background: linear-gradient(90deg,#124b71 0%,#01bddd 100%);
  border-top: 1px solid #eee;
  border-bottom: 3px solid #ccc;
  max-width: 1465px;
  margin: 2em auto;
  border-radius: 15px;*/
}


div#afnu_filters_wrapper .not-visible {
  display:none;
}

div#afnu_filters_wrapper div.recommended {
    display: flex;
    align-items: center;
    color: #cf576f;
    font-size: 13px;
    padding: 0.7em;
    line-height: 1;
    margin-right: 215px;
    font-weight: 800;
    position:relative;
    margin-bottom: -3.3em;
}

div#afnu_filters_wrapper div.window{
    font-size: 0.8em;
    margin: 0;
    border-radius:5px;
    padding: 1em 1.5em 0.5em;
    align-items: center;
    min-width: 335px;
    overflow: hidden;
    max-height: 650px;
    /*max-width: 88%;*/
}

div#afnu_filters_wrapper div.search {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

div#afnu_filters_wrapper div.search.not-visible {
  display: flex;
}

div#afnu_filters_wrapper div.tab {
  display: flex;
  /*justify-content: center;*/
  margin: 1em auto 0.5rem;
  flex-wrap: wrap;
  /*padding-top: 3em;
  padding-bottom: 3em;
  border-bottom: 2px dashed #777;*/
  background: #fff;
  padding: 2em;
  border-radius: 25px;
  max-width: 500px;
  overflow: auto;
  max-height: 540px;
}


div#afnu_filters_wrapper div.tab::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
  margin-left: 10px;
}

div#afnu_filters_wrapper div.tab::-webkit-scrollbar-track {
  margin: 2em 0;
  border-radius: 20px;
}

div#afnu_filters_wrapper div.tab::-webkit-scrollbar-thumb {

  background: #124b71;
  border-left: 4px white solid;
  background-clip: padding-box;
  /*border: 3px solid #124b71;*/  /* creates padding around scroll thumb */
}


div#afnu_filters_wrapper div.tab > div {
  padding: 1em 2em;
}


div#afnu_filters_wrapper div.tab.search { 
  display:flex;
}

div#afnu_filters_wrapper div.header {
  min-height: 20px;
  font-size: 1.2em;
}

div#afnu_filters_wrapper div.close{
  display: none;
}

div#afnu_filters_wrapper  h1 {
    width: 100%;
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 0.5em;
}

div#afnu_filters_wrapper  h2 {
  font-weight: 600;
  font-size: 1.2em;
  width: 100%;
  color: #555;
  margin-bottom: 1em;

}

div#afnu_filters_wrapper div.wrapper {
  display: contents;
  white-space: nowrap;
}

div#afnu_filters_wrapper div.inputbox{
  /*border-radius: 5px;
  width: auto;
  height:    60px;*/
  min-width: 16%;
  text-align: right;
  padding:   0 0.3em;
  color:     #000;
  margin: 0.4em 0;
  /*background-color: #fff;*/
}

div#afnu_filters_wrapper div.inputbox.button {
  display:inline-block;
  max-width: 48%;
  margin: 0 0 2em 0;
  width: 100%;
}

div#afnu_filters_wrapper div.inputbox.button.selected .content {
  color: #fff;
  background-color: #124b71;
}

div#afnu_filters_wrapper div.inputbox.button .content {
  /*padding: .85em .5em;*/
}

div#afnu_filters_wrapper div.inputbox.button .text {
  font-size: 0.85em;
  text-align: center !important;
}

div#afnu_filters_wrapper div.inputbox  div.content {
  color: #333;
  font-weight: 800;
  font-size: 1.5em;
  align-items: center;
  display: flex;
  /*line-height: 1.2;*/
  border: 1px solid #999;
  border-radius: 5px;
  padding: 0.5em;
  background-color:#1f5981;
  cursor: pointer;
  color: #fff;
}

div#afnu_filters_wrapper div.inputbox  div.content div.icon {
  color:#aaa;
  display:inline-block;
  margin:0 0 0 0.3em;
  font-size:0.9em;
}

div#afnu_filters_wrapper div.inputbox div.content div.icon div.body {
  font-size: 0.9em;
  color:#ccc;
}

div#afnu_filters_wrapper li {
  cursor: pointer;
}

div#afnu_filters_wrapper .tab.when .button .content:hover {
  cursor: pointer;
  background: #124b71;
  color: #fff
}


div#afnu_filters_wrapper li:hover {
  color: #cf576f;
  cursor: pointer;
}

div#afnu_filters_wrapper div.inputbox   div.content div.text {
  width: 100%;
  text-align: right;
}

div#afnu_filters_wrapper .search h1 {
  display: none;
}

div#afnu_filters_wrapper .search h2 {
  display: none;
}

div#afnu_filters_wrapper .search h6 {
  display: none;
}

div#afnu_filters_wrapper .switch.mobile {
  display: none;
}

div#afnu_filters_wrapper .switch.desktop {
  font-size: 1.2em;
  font-weight:700;
  color:#ddd;
  align-items: center;
  display: flex;
  width: 92%;
}

div#afnu_filters_wrapper .btn.ok {
  padding:0.5em 0 0;
  width: 100%;
  margin: 0 auto;
}

div#afnu_filters_wrapper .btn.ok > button {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 4%);
  border-radius: 5px;
  background:#124b71;
  width: 50%;
  padding: 0.5em;
}

div#afnu_filters_wrapper .btn.search {
  background: #fed102;
  min-width: 15%;
  color: #000;
  font-size: 1.2rem;
  /* border: 1px solid #aaa; */
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 4%);
  border-radius: 5px;
  padding: 0.6em;
  width: 100%;
  border-radius: 10px;
  font-weight: 800;
}

main {
  padding: 0;
}

#filters_wrapper .window {
  display: none;
}

div#afnu_filters_wrapper ul {
  font-size: 1.4em;
  font-weight:700;
  line-height:2.5em;
  padding: 0;
}

div#afnu_filters_wrapper li {
  display:flex;
  align-items: center;
  padding: 0 1em;
  line-height: 2.5;
}

div#afnu_filters_wrapper li.selected {
  background: #124b71;
  border-radius: 20px;
  color: #fff;
  line-height: 2.5;
}


div#afnu_filters_wrapper li.default {
  padding:0;
}

div#afnu_filters_wrapper li.default .text {
  padding:0;
}

div#afnu_filters_wrapper li.default div {
  display:flex;
  align-items: center;
  padding: 0 1em;
}

div#afnu_filters_wrapper li.default div.selected {
  background: #124b71;
  border-radius: 20px;
  color: #fff;
}

div#afnu_filters_wrapper li div.icon {
  padding:0 0 0 .5em;
}

.homepage-intro-wrapper {
  position: relative;
  padding-top: 30px;
 
}


.wave {
  background: #fff;
  display: none;
}

.wave svg {
  width:100%;
}

.wave.upsidedown {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  rotate: 180deg;
}


.homepage-intro {
  /*padding: 3%;*/
}

.homepage-intro-content {
  justify-content: space-evenly;
  align-items: center;
  font-family: "Segoe UI", Assistant;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  text-align: right;
  max-width: 1040px !important;
  margin: 0 auto;
}

.homepage-intro-content .image {
    align-self: self-end;

  /*max-width: 300px;
  width: 100%;
  height: 250px;
  margin-bottom: 1.5em;*/
}

.homepage-intro-content .slogal  {
  padding: 0 0 0 2.5em;
  width: 70%;
}

.homepage-intro-content .line1 {
  font-size: 3.5em;
  color: rgb(255, 255, 255);
  text-shadow: rgb(0 0 0 / 16%) 6px 3px 6px;
  line-height: 1.2;
}


.homepage-intro-content .line2 {
  color: rgb(255, 255, 255);
  font-size: 3.5em;
  text-shadow: rgb(0 0 0 / 16%) 6px 3px 6px;
}  

/**
 * Smooth scrolling inside an element
 */
/**
* Smooth scrolling on the whole document
*/
html {
  scroll-behavior: smooth;
}

/*
.homepage-intro-content .line3 {
  color: rgb(255, 255, 255);
  text-shadow: rgb(0 0 0 / 16%) 6px 3px 6px;
  margin-top: 0.5em;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1.3;  
}
*/
.homepage-intro-content .line3 {
  color: rgb(255, 255, 255);
  text-shadow: rgb(0 0 0 / 16%) 6px 3px 6px;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.5;  
  margin-top: 1em;
  }

.homepage-intro-content .line4 {
  color: rgb(255, 255, 255);
  text-shadow: rgb(0 0 0 / 16%) 6px 3px 6px;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.5;  
  margin-top: .25em;
}

.homepage-intro-content img {
  transform: scaleX(-1);
    /*width: 75%;*/
    width: 100%;
    max-width: 350px;
    /*margin-top: 3em;*/
}


.shortcuts-block {
  margin: 1em 0 1em;
  /*background: url(/images/symbol.png) 0% 0% repeat no-repeat padding-box rgba(6, 172, 201, 0.1);*/
  padding: 2em 1em 2em;
  background-position: right;
  background-size: contain;
  margin: 3em auto 0;
  background: #fff;
  display: flex;
  justify-content: center;
}

.shortcuts-block h2 {
  font-size: 2.2em;
    color: #555;
    line-height: 2em;
    margin: 0;
    padding-top: 0.5em;
    font-weight: 600;
}

.shortcuts-block .wrapper {
  display: flex;
  justify-content: center;
  align-items: inherit;
  max-width: 968px;
  margin: 0 auto;
  /*margin: 0 .5em 0 0;*/
}

.shortcuts-block .shortcut {
  opacity: 0.65;
  margin: 1em;
  height: 140px;
  display: flex;
  align-items: self-end;
}

.shortcuts-block .shortcut.search {
  display: none;
}

.shortcuts-block .shortcut.selected {
  opacity: 1;
}

.shortcuts-block .shortcut.selected .selector {  
  height: 7px;
  background: #1f5981;
  border-radius: 10px;
  width: 100%;
  margin: .5em 0;

}

.shortcuts-block .shortcut.selected .rectangle {
  /*background-color: #fed208 ;*/
}

/* dont show the new symbol on horizontal shortcuts view - because it's breaking the display and causing entire page to scroll left on iphone*/
.shortcuts-block.mobile .shortcut img.new {
  /*display: none;*/
}

.shortcuts-block .shortcut img.new {
  /*position:absolute;
  margin:-.8em -4em;*/
    position: relative;
    top: 30px;
    left: 45px;
}

.shortcuts-block .shortcut .image .rectangle {
  border-radius: 10px;
  background-size: cover;
  width: 110px;
  height: 110px;
}

.shortcuts-block .shortcut .image img:after {
  background: rgba(255, 255, 255, .5);
  /*transition: background .8s linear;*/
}

.shortcuts-block .shortcut .image img {
  -webkit-box-shadow: rgb(55 55 55 / 25%) 0px 6px 14px 1px;
  /*border-radius: 100px;*/
  background: #fff;
  padding: .5em;
  max-width: 110px;
}

.shortcuts-block .shortcut:hover {
    opacity: 1;
}

.shortcuts-block .shortcut .image img:hover {
  -webkit-box-shadow: rgba(0, 33, 179, 0.5) 0px 6px 14px 1px;
  /*background-color: #eee*/
}

.shortcuts-block .shortcut a {
  text-decoration: none;
}


.shortcuts-block .shortcut .label { 
  margin: .5em 0 0 0;
  font-size: 1.2rem;
  color: #555;
  line-height: 1;
  text-align: center;
  font-weight: 800;
}

.change_destination_heading {
  font-weight: 800;
  padding: 1em 0 0 0.2em;
  color: #1f5981;
}

.change_destination_heading h3.overview {
    font-size: .8em;
    line-height: 1;
    color: #fff;
}

.change_destination_heading h2 {
  font-size: 2.5em;
  font-weight: 800;
}

.change_destination_heading h2 > .city_name_wrapper {
  /*color: #01bddd;*/
  font-weight: 800;
  cursor: pointer;
  margin: 0 0.1em;
}

.change_destination_heading h2 > .city_name_wrapper > .city_name {
  border-bottom: 1px dashed;
  cursor: pointer;
}

.media-block {
  margin: 1em 0 1em;
  background: url(/images/symbol.png) 0% 0% repeat no-repeat padding-box rgba(6, 172, 201, 0.1);
  padding: 2em 1em 3em;
  background-position: right;
  background-size: contain;
  /*background-blend-mode: soft-light;
  background-color: #efefef;*/
}

.media-block h2 { 
  color: rgb(19, 74, 113);
    font-weight: 800;
    font-size: 3em;
    margin: 0.3em 0 0.5em 0;
    /*margin: 0px 0px 1em;*/
}

.media-block .wrapper { 
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-block h3 { 
  color: rgb(19, 74, 113);
  font-weight: 600;
}

.media-block .item { 
  max-width: 600px;
  width: 100%;
}

.media-block .item div { 
    /*width: 552px;*/
    min-height: 335px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.media-block .item .item1 {
  background: url(/images/media1.jpg) 0% 0% / cover;
}


.media-block .item .item2 {
  background: url(/images/media2.jpg) 0% 0% / cover;
}

.media-block .item .item3 {
  background: url(/images/media3.jpg) 0% 0% / cover;
}

.media-block .item.first {
  margin: 0 0 0 3em;
}

.media-block .item.second {
  margin: 0 3em 0 0;
}

.media-block .item.third {
  display: none;
}

.media-block .icon {
  color:#fff;
  font-size:4em;
}

.filters_toggle_static {
  display:none;
}

@media only screen and (max-width: 980px) {
  .shortcuts-block.mobile .wrapper  {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  
}

@media only screen and (max-width:500px) {

  .App.overlay >.content {
    min-height: 750px;
  }

  .homepage-intro-wrapper {
    padding-top: 0;
  }

  
  .promotionBillboard {
       
       margin: 0 1em 2em 1em;
       min-height: 71vw; /*280px;*/
       background-size: cover;
  }


  .card {
    min-width: 90%;
    width: inherit;
  }

  main {
    padding-top: 1em;
  }
}

@media only screen and (max-width: 768px) {

   .Cal__Header__root {
    border-radius: 0 !important;
   }

   .Cal__Container__root {
    position: fixed !important;
    top: 0 !important;
    z-index: 10009 !important;
   }

  .homepage-intro {
    /*background: linear-gradient(    235deg,    #06ACC9 50%,    rgba(0,0,0, 0)  ),url(/images/header-background-beach.jpg) no-repeat left top;*/
    /*background: linear-gradient(    235deg,    rgb(16 171 207) 30%,    rgba(1 189 221)  ),url(/images/header-background-beach.jpg) no-repeat left top;*/
    /*background: url(/images/header-background-beach.jpg) 64% 2% / cover no-repeat padding-box padding-box rgb(24, 192, 195);*/
    /*background: linear-gradient(    235deg,   rgb(31 89 129) 70%,    rgba(1 189 221)  ),url(/images/header-background-beach.jpg) no-repeat left top;*/
    background-size: cover;
    /*max-height: 470px;*/
  }

  .homepage-intro-content  {
    text-align: right;
    font-size: 8px;
    line-height: 1.5;
    justify-content: center;
  }


  .homepage-intro-content .slogan {
    margin: 1.5em 2em 1.5em .25em;
  }

  .homepage-intro-content .image {
    width: 50%;
  }

  .homepage-intro-content .line1 {
    font-size: 3.25em;
    line-height: 1.5;
  }

  .homepage-intro-content .line2 {
      margin-top: 0;
      font-size: 3.25em;
      display: inline-flex;
      line-height: 1.25;
  }

  .homepage-intro-content .line3 {
    margin: 1em 0;
    font-size: 2em;
    font-size: 2em;
    margin-top: 1.75em;
  }

  .homepage-intro-content .line4 {
    height: 2em;
  }


  /*.homepage-intro-content img {
    margin-top: 0;
  }*/

  .shortcuts-block {
    width: 100%;
    min-width: 100%;
    padding: 0;
    margin-top: 0;
    display: block;
  }

  .shortcuts-block .shortcut.selected .selector {  
    width: 100%;
    max-width: 90px;

  }
  

  .shortcuts-block h2 {
    font-size: 1.2em;
  }

  .shortcuts-block .wrapper {
    flex-wrap: wrap;
    justify-content: normal;
  }

  .shortcuts-block.mobile {
    padding: .5em .5em 1em 0;
  }

  .shortcuts-block.mobile .shortcut {
    max-width: 25%;
    margin: 1em 0.5em;
   
  }

  .shortcuts-block .shortcut .image .rectangle {
    width: 90px;
    height: 100px;
  }

  .shortcuts-block.mobile .wrapper {
    max-height: 200px;
    flex-direction: column;
    padding-top: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .shortcuts-block.mobile .wrapper::-webkit-scrollbar {
    display: none;
  }  

  .shortcuts-block.mobile .shortcut .label {
    /*padding: .25em .25em 0 .25em;*/
    padding: 0;
    max-width: 90px;
  }

  .shortcuts-block.mobile .shortcut .image img {
    max-width: 100%;
  }

  .shortcuts-block .shortcut {
    margin: 1em 0 0 1em;
    max-width: 25%;
  } 

  /*.shortcuts-block .shortcut.passover {
    display: none;
  } */

  /*.shortcuts-block .shortcut.summer {
    display: none;
  }*/

  .shortcuts-block.mobile .shortcut.passover {
    display: inherit; /* Force showing passover icon in inner pages */
  } 

  .shortcuts-block.mobile .shortcut.summer {
    display: inherit; /* Force showing passover icon in inner pages */
  } 

  .shortcuts-block .shortcut.search {
    display: inherit;
  } 

  .shortcuts-block .shortcut .image img {
    max-width: 78px;
  }

  .shortcuts-block .shortcut .label {
    font-size: .9em;
  }

  .shortcuts-block .shortcut img.new {
    margin:-.6em -3em;
    width: 36px;
    height: 36px;
    top: 15px;
    left: 40px;
  }
  
  
.change_destination_heading {
  padding: 0 0 .5em;
  text-align: right;
  margin-right: 17px;
  margin-left: 17px;

}

.change_destination_heading h2 {
  font-size: 1.5em;
}

.change_destination_heading h2 > .city_name_wrapper {
  /*color: #01bddd;*/
  font-weight: 800;
  cursor: pointer;
}

.change_destination_heading h2 > .city_name_wrapper > .city_name {
  border-bottom: 1px dashed;
  cursor: pointer;
}


  .media-block {
    padding: .75em 1em 0em;
    background-size: cover;
    margin: 2em 0 1em;

  }

  .media-block .wrapper {
    /*flex-direction: column;*/
    flex-direction: row;
    overflow: scroll;
    justify-content: right;
  }

  .media-block h2 { 
    font-size: 1.7em;
    /*margin-bottom: 0.5em;*/
    margin: 0;
  }

  .media-block h3 { 
    font-size: 1.2em;
  }

  .media-block .item {
    min-width: 80%;
  }

  .media-block .item div { 
    min-height: 165px;
  }

  .media-block .item.first { 
    /*margin:0;*/
    margin: 0.5em;
  }

  .media-block .item.second { 
    margin:1em 0;
    /*display:none;*/
  }

  .media-block .item.third { 
    margin:0 0.5em 0 0;
    display:block;
  }


  .media-block .icon {
    font-size:3em;
  }

  div#afnu_filters_wrapper.disabled {
    display: none;
  }

  div#afnu_filters_wrapper {
    padding: 2em calc(3%) 2em;
    position:fixed;
    top:0;
    z-index:10;
    /*background:linear-gradient(45deg,#124b71 3%,#01bddd 45%);*/
    background-color: #01bddd;
    background-size: contain !important; /*cover !important;*/
    /*background: url(https://st3.depositphotos.com/35779012/37091/i/450/depositphotos_370915064-stock-photo-two-friends-stand-background-stars.jpg);*/
    background :url(/images/depositphotos_159627686-stock-photo-young-pretty-fashion-woman.jpg);
    /*background: url(https://st2.depositphotos.com/10983638/47346/i/450/depositphotos_473466656-stock-photo-woman-swims-inflatable-circle-pool.jpg)*/
    min-height: calc(100%);
    border-radius: 0;
    margin-top: 0;
    border: none;

  }

  div#afnu_filters_wrapper div.wrapper {
    display:flex;
    width: 100%;
    justify-content: space-between;
  }

  div#afnu_filters_wrapper div.window {
    background:#fff;
    padding: 1em;
    min-height: 500px;
    max-height: 90vh;
  }

  div#afnu_filters_wrapper div.search.not-visible {
    display: none;
  }

  div#afnu_filters_wrapper .btn.ok { 
    padding: 0;
    width: 100%;
  }
  
  div#afnu_filters_wrapper .btn.ok > button {
      width: 100%;
  }
  
div#afnu_filters_wrapper.not-visible {
  display:none;
}

  div#afnu_filters_wrapper div.tab {
    min-height: 440px;
    max-width: 100%;
    padding: 0 1em 0;
  }

  
div#afnu_filters_wrapper div.tab > div {
  padding: 0;
}


  div#afnu_filters_wrapper div.tab  .inputbox {
    width: 100%;
  }

  div#afnu_filters_wrapper div.close {
    width: 100%;
    display: inline-block;
  }
  
  
  div#afnu_filters_wrapper div.inputbox {
    width: 100%;
    color: #666;
    margin: 0.4em 0.2em;
    /*border:1px solid #999;*/
  }

    
  div#afnu_filters_wrapper h1 {
    font-weight: 800;
    color: #124b71;
    font-size: calc(1.425rem + .9vw);
    line-height: 1.2;
    display: inline !important;
    width: 100%;
    margin: 0;
  }

  div#afnu_filters_wrapper h2 {
    font-weight: 600;
    width: 100%;
    font-size: 1rem;
    line-height: 1.2;
    color: #666;
    margin: 0.5em 0.25em;
    display: inline !important;
  }

  div#afnu_filters_wrapper h6 {
    margin-top: 1em;
    text-align:right;
    font-weight:bold;
    width:100%;
    display: inline;
    
  }

  div#afnu_filters_wrapper .btn.search {
    width: 100%;
    margin-top: 1em;
    padding: .5em;
    /*background-color: #124b71;
    color: #fff;
    */
  }

  div#afnu_filters_wrapper .switch {
    width:100%;;
    text-align:right;
    margin-top:1.5em;
    font-size: 1.45em;
    font-weight:700;
    color:#777;
    display: block;

  }
  
  div#afnu_filters_wrapper .switch.desktop {
    display: block;
  }
  
  .App.Terms Main, .App.MemberTerms Main, .App.About Main , .App.Media Main , .App.About Main{ 
    max-width: 90%;
  }
  /* FAQ */
  section.cd-faq {
    width: 100%;
    margin: 0 auto;
  }
  
  ul.cd-faq__categories li {
    width: 40%;
  }
  h1 {font-size: 1.5rem !important;}

  .order_page .warning-top {
    border-left: inherit;
    border-right: inherit;
  }

  .order_page .main_title {
    background: #eee;
    color: #000;
  }

  .Deal .mobile_navigation {display:none;}

  .order_page .hotel_content {
    padding: 0.5rem 1rem 0.5rem 0.5rem;
  }
  
  .order_page .dealDetails .bags, .deal_content .bags {
    padding: 0.1rem;
    margin: 0.1rem auto 0;
    font-size: 0.8rem;
    width: 90%;
  }

  .order_page .dealDetails .card.hotel .hotel_details .rating {
    font-size: .8rem;
  }

  .order_page .dealDetails .card.hotel .hotel_details {
    font-size: .9rem;
  }

  .order_page .dealDetails .card.hotel .hotel_image {
    width: 110px;
    height: 110px;
  }

  .order_page .dealDetails .card.hotel .hotel_title { 
    font-size: 1rem;
  }

  .order_page  .dealDetails .airline img {
    width: 24px;
    height: 24px;
  }

  .order_page  .route { 
    padding: 1rem 0.5rem;
  }

  .order_page .deal_content .from, .order_page .deal_content .to {
    width: 25%;
  }

  .order_page .duration {
    font-size: .8rem;
    width: 50%;
  }

  .order_page .deal_content { 
    font-size: 0.9rem;
  }

  .full_warning {
    font-size: 0.9rem;
  } 
  
  .full_warning button {
    margin: 0;
    padding: 0.2rem !important;
    width: 60% !important;
}
  .order_page .warning-top {
    font-size: .75rem;
  }

  .order_page .warning2 {
    margin: 0.5rem 1rem 0;
    font-size: .85rem;
    border:1px dashed #999;
    padding: 1rem;
  }

  
  .book_deal .hotel .col.logo img {
    width: 85%;
  }
  
  
  .order_page .main_title  .dates { 
    font-weight: 400 !important;
    font-size: 1rem;
  }

  .order_page .comment {
    color: #666;
    font-size: .7rem;
    text-align:  center;
  }
  
  .order_page {
    text-align:right;
    width:65%;
    margin:0 auto;
  }

  .order_page .main_title > h1 {
    font-size: 1.4rem !important;
    margin: .5rem;
  }
  
  .book_deal_content .cols_wrapper .col1 .benefits {
    font-size: .8rem;
    margin-bottom: 0;
  }
  
  .order_page .deal_total {
    font-size: 1rem;
    padding: 0.8rem;
  }
  

  .book_deal_content {
    text-align: right;
    padding: 0 1rem 1rem;
    font-size: 0.9rem;
    line-height: 1.5;
    width: 100%;
    border-bottom: 1px solid #ccc;
    
  }
  
  .book_deal_content .cols_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .book_deal .price .amount {
    font-size: 30px; 
    font-weight: 600;
    line-height: 1.1;
    border-bottom: 1px solid #aaa;
    text-align: center;
  }
  
  .book_deal .price .caption { 
    text-align: center; 
    font-size: .6rem;
    font-weight: 600;
  }
  
  .book_deal_content .cols_wrapper.flight_title  h3 {
    margin: 0;
    display: inline;
    font-size: .9rem;
  }
  
  .book_deal_content .cols_wrapper.flight_title  { 
    margin:1rem 0 0.5rem;
  }
  
  
  .book_deal_content .cols_wrapper .col1 { width: 80%; }
  .book_deal_content .cols_wrapper .col2 {
    width: 20%;
  }

  
.book_deal_content .cols_wrapper .col2 img {
  width: 100%;
}
  
  .book_deal .cols_wrapper { width: 100%; }
  .book_deal .cols_wrapper .col { width: 33%; }
  
  .book_deal_content ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .book_deal_content ul li:before {
    content: '✓';
    margin: 0 0 0 .5rem;
    font-weight: 800;
  }
  
  .book_deal_content .benefits {
    font-size: 0.8rem;
    font-weight: 600;
  }
  
  .book_deal .hotel_content  {
    padding: 0.5rem 1rem 2rem 0.5rem;
    width: 100%;
  }
  
  .book_deal .hotel_content  h3 {text-align: right;} 
  
  .book_deal_content .hotel  {
    text-align: right;
  }
  
  
  .order_page .dealDetails .card.hotel .hotel_title h3 {  
    font-size: .9rem;
  }

  .dealDetails u {
    color: #cf576f;
  }
  
  .book_deal .hotel .cols_wrapper {
    display: flex;
    text-align: justify;
    
    align-items: center;
  }
  .book_deal .hotel .col.price {flex:1;}
  .book_deal .hotel .col.logo {
    flex: 1.8 1;
    margin-left: .5rem;  
  }
  .book_deal .hotel .col.button {flex:2;}
  
  .book_deal .hotel_content button {
    padding: .2em .5em .3em .5em !important;
    font-size: .8rem !important;
    min-width: 90%;
  }
  
  
  
  .book_deal .hotel_content  .img_agoda {
    width: 80%;
    float: right;
  }
  
  
  .book_deal .hotel_content .hotel_title {
    font-size: 90%;
  }
  
  .book_deal .hotel_content hr  {
    margin: 1rem 0;
  }
  
  .book_deal button {
    padding: 0;
    margin: .5rem 0 !important;
    font-size: 1rem !important;
    line-height: 1;
    padding: .1em 1em .1em 1em !important;
    border-radius: 10px !important;
  }
  
  .card .title.sideTab, .order_page .card .title.sideTab {
    /*padding: 0;*/
    font-size: 1.1rem;
    font-weight: normal;
  }

  

.carousel {
    background: #fff;
    font-size: 1.5rem;
    padding: 0;
    font-weight: 700;
}

.carousel .slogan {
  margin-top: -1.3rem;
  padding-bottom: 0;
}

.carousel .slogan img {
  margin: 0 0 -1.35rem 0;
  width: 108px;
}

.carousel img {
  width: 100%;
}

.carousel p {
  color: #000;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
}

  header.no-image {
    margin-top: 70px;
  }

  .Deal  header.no-image {
    margin-top: 40px;
  }


  .checkbox .overlay {
    margin: 0 0 -10px 10px;
  }
      
      .headerImg_mobile.main {
        display:block;
        width: 100%;
        padding: 1.5rem 0.5rem 2rem;
        margin-top: 0;
      }

      .headerImg_mobile .slogan {
        max-width: 100%; 
        line-height: 1.2rem;
      }

      .headerImg_mobile .slogan {
        font-size: 0.9rem;
      }

      .headerImg_mobile .first_heading {
        font-size: 1.4em;
      }

      .headerImg_mobile .second_heading {
        line-height: 0;
        font-size: 2.1rem; 
      }
      
      .headerImg_mobile  button { 
        font-size: 0.8rem;
      }


      .top-header {
        background-image: url(/images/intro/depositphotos_50930169-stock-photo-young-couple-of-tourist-in-small.jpg);
        background-size: cover;
        background-position-x: right;
        background-position-y: top;
        background-attachment: scroll;
        height: inherit;
        position: relative;
      }

      .top-header::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0);
        /*background-image: linear-gradient(rgb(0 0 0 /80%), rgb(0 0 0 /0%), rgb(0 0 0 /0%));*/
        /*background-image: linear-gradient(rgb(0 0 0 /75%), rgb(0 0 0 /42%), rgb(0 0 0 /-20%));*/
        background-image: linear-gradient(56deg,rgba(38,48,38,26%) 34.23%,rgba(10,24,45,0.7) 65.86%);
        max-height: 360px;
      }

      .top-header.destination::before{
        max-height: 280px;
      }
      

      .headerNav {
        justify-content: center;
        /*background: -webkit-linear-gradient(90deg,#01bddd 3%,#124b71  45%);
        background: -webkit-gradient(linear,left top,right top,color-stop(3%,#01bddd),color-stop(45%,#124b71  ));
        background: -webkit-linear-gradient(left,#01bddd 3%,#124b71  45%);
        background: -o-linear-gradient(left,#01bddd 3%,#124b71  45%);
        background: linear-gradient(90deg,#01bddd 3%,#124b71  45%);*/
        /*background: #fff;*/
        
        display: flex;
        flex-direction: column;
        height: auto !important;
        padding: .5em .75em 0;
        /* ... (Your existing .headerNav styles) ... */
        opacity: 0.98;
        position: relative;
        width: 100%; /* add width 100% */
        z-index: 1000;/* add z-index 1000 */
        -webkit-animation-name: animationSlide;
        -o-animation-name: animationSlide;
        animation-name: animationSlide;
        -webkit-animation-duration: 2s;
        -o-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;
        transition-property: all;
        transition-duration: 2s;
        transition-timing-function: cubic-bezier(0, 1, 1, 2);
      }

    
    .headerNav.fixed {
        /*position: fixed;*/ /* Remove position fixed*/
        /*top: 0;*/ /* Remove top 0 */
        /*left: 0; /* Remove left 0 */
        /*width: 100%; /* Remove width 100% */
        /*z-index: 1000;*/ /* Remove z-index 1000*/
        /*background: linear-gradient(to left, #1f5989 40%, #01bddd); /* Modified gradient */
        position: fixed; /* add position fixed*/
        top: 0; /* add top 0 */
        left: 0; /* add left 0 */
        background-color: #1f5981;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        /*background-color: rgba(255, 255, 255, 0); /* Start transparent*/
        padding: .5em .75em .25em;
        color: #555;
        
    }

    .headerNav.fixed .logo a {color: #fff; }

    .headerNav .logo .logo-svg {
        height:45px;
        color:#fff;
        margin-top: 0;
    }

    div#headerNav.fixed .nav-sections .fa {
        color: #fff;
    }
    
    .headerNav.fixed .nav-sections {
        background: none; /* Remove background in nav-sections so it is transparent */
    }
    

      .headerNav .right-nav {display:block;}


      #headerNav .nav-sections {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
      }

      #headerNav .nav-sections .left-nav,
      #headerNav .nav-sections .right-nav {
        display: inherit;
        color: #fff;
        font-size: 1.5rem;
        padding: 0;
        min-width: 2.5rem;
        display: flex !important;
        align-items: center;
        justify-content: center;

      }


      .desktop_navigation {
        display:none;
      }

      .mobile_navigation {
        display:block;
        direction: rtl;
      }

      .mobile_navigation ul {
        justify-content: space-around;
        margin:0 auto;
      }

      .mobile_navigation ul li {
        min-width: 4rem;
      }

      .mobile_navigation ul li { 
        border-left: 1px solid #ccc;
        padding: 0 .3rem 0 .7rem;
      }

      .mobile_navigation ul li a {
        margin: 0 !important;
      }
      
      
      span.spn_vip {
        padding: 0 .2rem 0 0;
        color: #DEB20B;
        font-weight: 800;
    }

      nav li.active a {
        background: #feed35;
        color: #333;
        font-weight: 700;
        border: 1px solid #888;
      }


      .mobile_navigation ul li.vip{  
        border-left: none;
      }
      
      .mobile_navigation ul li i, .mobile_navigation ul li svg, .mobile_navigation  .vip_icon {display:none !important;}

      /*.headerNav .overlay {
        background: #135375;
        width: 100%;
        position: absolute;
        min-height: 50px;
        opacity: 0.7;
        top: 0;
    }*/

    .mobile_navigation li.members a {
      color: #deb20b;
    }

    .mobile_navigation .vip_icon {
      display:block;
      width: 50px;
      height: 25px;
      margin: 0 auto;
    }
    
      

      nav {
        place-self: center;
  
      }

      nav > ul {
        padding:0;
        justify-content: space-evenly;
        margin-top: 1rem;
      }

      nav li {
        padding: 0;
      }

      nav li a {
        font-size: 0.8rem;
        /*padding: 0.1rem 0.5rem !important;*/

      }


      #logo > img {
        height: 3rem;
      
      }



      .headerImg.main {
        background: url(/images/header/bg1.jpg);
        background-size: cover !important;
        background-repeat: no-repeat;
        background-position: top;
        background-attachment: unset;
        background-color: #4b95cf;
        width: 100%;
        min-height: inherit;
        padding: 6.5rem 5rem;
       height: inherit;
    }
    

    
      .headerImg .wrapper {
        margin-right: 30px;
      }

      .headerImg .slogan {
        font-size: 1.3rem;
        line-height: 1.5rem;
        margin:0 0.5rem;
        padding: 0;
        position: absolute;
    }
    
      .headerImg .slogan h1 {
        line-height: 0;
        font-weight: 800;
        font-size: 2.5rem;
        margin: 1.5rem 0;
      }

      /*
      .filters_wrapper {
        margin: 0 auto;
        position:fixed;
        bottom:0;
        z-index: 1;
        width: 100%;
      }*/

      .filters.not-visible {display:none !important;}
      .filters_toggle.not-visible {display:none !important;}

      .filters_toggle.float {
        display: block;
        /*width: 48%;*/
        width: 100%;
        /*border-radius: 10px;*/
        border-radius: 0;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 700;
        /*border: 1px solid #fff;*/
        margin: 0 auto;
        display:flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        /*bottom: 10px;*/
        bottom: -1px;
        z-index: 1;
        font-size: 1.35em;
        padding: 0.25em 0.1em 0.35em 0.35em;
        min-height: 50px;
        border: none;
        border-top: 2px solid #eee;
      }

      .search_toggles {
        margin: 0 1em 0 1em ;
      }


      .filters_toggle_static {
        /* margin: 0px auto; */
        display: flex;
        align-items: center;
        justify-content: center;
        /* position: fixed; */
        /* bottom: 10px; */
        /* z-index: 1; */
        width: 42%;
        border-radius: 10px;
        font-weight: 600;
        border: 1px solid #fff;
        min-height: 42px;
        padding-left: .5em;
    }

      .filters_toggle {
          /* margin: 0px auto; */
          display: flex;
          align-items: center;
          justify-content: center;
          /* position: fixed; */
          /* bottom: 10px; */
          /* z-index: 1; */
          width: 40%;
          border-radius: 10px;
          font-weight: 600;
          border: 1px solid #fff;
          min-height: 42px;
          padding-left: .5em;
      }

      .filters_wrapper.not-visible {
        transition: all 1s ease; /* our nice transition */
        -webkit-transform: translate(0%, 100%);
        -ms-transform: translate(0%, 100%);
        transform: translate(0%, 100%); 
        bottom: 70px;
      }

      .filters_wrapper.visible {
        transition: all 1s ease; /* our nice transition */
        -webkit-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        transform: translate(0, 0%);
      }

      .filters_wrapper.picaboo {
        transition: all 0.5s ease; /* our nice transition */
        -webkit-transform: translate(0%, 50%);
        -ms-transform: translate(0%, 50%);
        transform: translate(0, 50%);

      }

      @keyframes slide-in {
        100% { transform: translateX(0%); }
      }

      @-webkit-keyframes slide-in {
        100% { -webkit-transform: translateX(0%); }
      }
        
      @keyframes slide-out {
        0% { transform: translateY(0%); }
        100% { transform: translateY(100%); }
      }

      @-webkit-keyframes slide-out {
        0% { -webkit-transform: translateY(0%); }
        100% { -webkit-transform: translateY(100%); }
      }
      .filters_toggle  i{
        vertical-align: text-top;
        margin: 0 0.4rem 0 0;
        -webkit-animation: slide 0.5s forwards;
        -webkit-animation-delay: 2s;
        animation: slide 0.5s forwards;
        animation-delay: 2s;
      }

      /*
      filters {
          background: #efefef;
          padding: 2.2rem 1rem 2.5rem;
          border: 1px solid #999;
          margin: 20px auto 0;
          border-top: 6px solid #ccc;
          border-left: 3px solid #ccc;
          border-right: 3px solid #ccc;
      }*/

      .filters .lbl_btn { padding-top: 0.5rem;}

      .filters_toggle {
/*        
        background: #cf576f;
        color: #fff;
*/
        line-height: 2.5rem;
        color : #222;
        background-color : #fed102;
      }

      .filters_toggle_static {
        background: #134A71;
        color: #FEED3A;
        /*background: #cf576f;*/
        border: 1px;
        line-height: 2.5rem;
        /*color: #fff;*/
        font-size: 1.05em;
        font-weight: 700;
      }

      .filters_toggle .fa-sort-down {

      }

      .filters_toggle .fa-sort-up {
        vertical-align: sub;
      }

      .filters label {
        flex: auto;
        min-width: 50%;
        font-size: .9rem;
        margin: 0 auto;
        padding-bottom: 1em;
      }

      .filters .lbl_btn {
        max-width: 99%;
      }

      .filters select {
        font-size: .85rem;
        font-weight: 600;
        height: 2.75rem;
        padding: 0 1.25rem 0 0;
        margin: 0 0.2rem 0.8rem;    
      }

      .filters option span {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      
      .filters .btn.search {
        height: 2.25rem;
        padding: 0;
        margin: 0 0 10px;
        min-width: 100%;
      }



    .preloader {
      /*position: fixed;*/
      top: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      padding: 0;
      background: none;
      z-index: 1000;
      margin: 0 auto;
    }
    
    .preloader .wrapper {
      opacity: 0.7;
      height: 100%;
    }

    .preloader .image_wrapper {
      width: 100%;
      /*position: fixed;*/
      top: 40%;
    }    

    .preloader h2 {
      font-size: 1rem;
      display:none;
      }

    .h2.hide-item {padding: 1rem auto 0;}

    .order_page {
      width:100%;
    }

    .order_wrapper h1 {
      font-size:1.5rem !important;
    }

    .order_wrapper .row.head div {
      font-size: 0.8rem;
      padding: 0.2rem;
    }

    .order_wrapper .row.content .price {
      font-size: 1.4rem;
      font-weight: 700;
    }

    .order_wrapper .row.content .price.small {
      font-size:1rem;
    }

    .order_wrapper .row.content > div > div {
      font-size: 0.8rem;
    }

    .order_wrapper .title .dates {
      display:block;
    }
    

    .comment img {width:50px;}

    .order_details .warning {
      font-size: 1rem;
      margin: 2rem 0;
    }

    .order_details .warning h2 {
       font-size: 1.3rem;
    }


    .flight_details {
      margin-bottom: 1rem;
      width:inherit;
    }

    .order_details .warning.border {padding: .4rem;}

    .order_details .box > .first_col {display:none;}

    .order_details .box > .center_col {padding:0;}

    .order_details .box > .center_col {border:none;}

    .airline_logo {margin: 0 0 0 0.3rem;}
    .airline_logo img {width:24px;}

    .order_details .box > .btn_col {
      padding-right:0.5rem;
      flex: 1;
    }

    .order_details .flight > .btn_col ul {display:none;}

    .btn_col img {
      max-width: 75px;
    }

    
    .btn_col .main_price  {
      text-align:center;
      font-size: 1rem;
    }

    .btn_col .main_price > .price {
      font-size: 1.3rem;
      margin-top: 10px;
    }

    .flight_details .line {margin: 0 0.5rem 0 0.5rem;}

    .order_details .comment {
      font-size: 0.7rem;
      color:#aaa;
  }

  .order_details button {
    width: 100%;
    font-size: 1rem;
    margin-top: 0.5rem;
    padding: 0.1rem 1rem;
  }

  .order_details .box {
    margin-top: 0;
    padding: 1rem 0.5rem;
  }

  h3.mobile_only {
    margin:1.5rem 0 0.5rem 0;
  }

  .mobile_only {
    display: block;
  }

  ul.mobile_only {
    flex: 1;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    margin-right: 2rem;
    z-index: 100;
    list-style: inside;
    display: inline-flex;
  }

  ul.mobile_only li { 
    width: 50%;
  }

  .hotel .content {
    text-align: right;
    flex: 2;
    display: flex;
    padding: 0 0.5rem;
    flex-direction: column;
    direction: ltr;
  }

  .order_details .box > .center_col {
    border: none;
    flex-grow: 6;
    flex: 4;
    display: flex;
  }

  .hotel img {
    max-width:100%;
    width: 100%;
  }

  .hotel .benefits > div {
    font-size: 0.8rem;
  }

  .hotel .photo img {
    width: 100%;
  }
  
  .hotel .div_center .icon {
    display:inline-block;
    margin: 0 0 0 0.2rem;
  }

  .hotel h3 {margin:0;}

  .order_details .box > .center_col h3 {
    font-size: 1.1rem;
  }
  
  div.div_hr {
    width: 80%;
    margin-top: .3rem;
  }

  div.div_hr:before, div.div_hr:after {
    left: 45%;
  }

  h2.light {
    font-size: 1.4rem;
    margin-top: 1rem;
  }

  div.div_hr.black {
    width: 80%;
    margin: 0.5rem auto 2rem;
  }

  .hotel .center_col .photo {
    display: none; 
    /*
    flex: 1;width:100%;
    */
  }

  .hotel_wrapper  {
    margin-top: 0;
  }

  .hotel_wrapper .photo {
    display: block;
  }

  .box.hotel .btn_col img.agoda {
    max-width: 75%;
  }

  .box.hotel .btn_col img.booking {
    max-width: initial;
    width: 100px;
  }

  .subtitle2 {
	position: fixed;
	bottom: 0;
	z-index: 100000;
	background: #124b71;
	color: #fff;
	display: none;
	flex-flow: wrap;
  }

  .subtitle2 a {
	margin:0;
	padding:0;
	font-size: 1rem;
        background:#cf576f;
        text-decoration: none;
        padding: 0.2rem 1.5rem;
        margin-top: 0.5rem;
        border-radius: 5px;
        color:  #fff;
  }
  .subtitle2 div {
	line-height: 1.5rem;
	margin: 0 auto;
  }

  .subtitle .hours, .seconds, .minutes {
	background-color: #ffffff;
        color: #cf576f;
  }


  .App .partners {
    color: #666;
    font-size: 0.85rem;
    margin: 1rem 0 0.5rem;
    font-weight: bold;
    width: 100%;
  }
  
  
  .App .partners.hide-mobile {
    display:none !important;
  }
  
  .App .partners img.agoda {
    height: 25px;
  }
  
  .App .partners img.kiwi {
    height: 30px;
    padding: 0;
  }
  
  .App .partners img.booking {
    width: 100px;
  }

  nav {
    background:#fff;
    width: 100%;
  }

  nav i.fa, svg.svg-inline--fa {
    margin: 0 auto;
    display:block;
  }

  nav a.active {
    color: yellow;
  }

  nav li a {
    color: #687b90 !important;
    background: #fff !important;
  }

  .App.Members .card {
    width: 80%;
  }

  .App.Members .icon { 
    margin-top: 1rem;
  }

  .App.Members .btns button {
    display: block;
    margin: 1rem auto 0;
  }

  .App.MemberSignup .facebook-login-btn, .App.MemberSignup .google-login-btn {
    width: 100%;
  }

  .App.MemberSignup .terms {
    text-align: right;
    padding: 0 1rem;
    line-height: 1.5rem;
  }

  .App.Members  Main {
    margin: 2em auto 0;
  }

  .App.Media Main {
    margin: 0em auto 0;
    padding: 1rem 1rem;
    max-width: 100%;
  }

  .App.About Main {
    max-width: 100%;
    font-size: 1rem;
  }

  .App.About Main h1 {
    font-size: 1.6rem;
  }

  
  .App.Contact iframe {
    max-width: 94% !important;
  }
  
  footer {
    padding: 0.5rem 0;
    background: #666;
    color: #fff;
    font-size: 0.9rem;
    z-index: 100000;
    width: 100%;
    /*position: absolute;*/
  }

  footer a {
    padding: 0 0.3rem;
  }

  footer.hide {display:none;}
  main {
    margin: 0;
    padding: 0;
  }

  footer .copyrights {display:block;}
  footer .copyrights-seperator {display:none;}

  .menu-content li {
    font-size: 1.3rem;
    padding: .8rem 1.5rem;
  }
  
  .menu-content li.first-item {
    font-size: 1.6rem;
  }

  .menu-content {
    height: 640px;
    position: fixed;
    top: 70px;
  }

  .menu-content li.mobile-only {display:block;}
}

.video-container {
  overflow: hidden;
  position: relative;
  width:100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}